import { AuthContext } from '@fireactjs/core';
import { SubscriptionContext, SubscriptionProvider } from '@fireactjs/saas';
import React from 'react';
export const UserSettingsContext = React.createContext();
export const UserSettingsProvider = ({ children }) => {
    const [settings, setSettings] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const authContext = React.useContext(AuthContext);
    const { firebaseApp, authUser } = authContext;
    const {subscription} = React.useContext(SubscriptionContext);
    const [appDefinition, setAppDefinition] = React.useState(null);
    const userId = authUser.user.uid;
	React.useEffect(() => {
		var firebaseApp = authContext ? authContext.firebaseApp : null;
		if (firebaseApp) {
			const CloudFunctions = firebaseApp.functions();
            var subscriptionId = subscription ? subscription.id : null;
			setLoading(true);
			const func = CloudFunctions.httpsCallable('backend-getProjectDefinitions');
            const user = firebaseApp.auth().currentUser;
            if(!user){
                setLoading(false);
                const res = {functions:CloudFunctions};
                setAppDefinition(res);
                return;
            }
            
            var tempresult;
            user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                func({subscriptionId,idToken }).then(result => {
                    setLoading(false);
                    tempresult = result;
                    result.data.functions = CloudFunctions;
                    result.data.idToken = idToken;
                    setAppDefinition(result.data);
                }).catch(error => {
                    setLoading(false);
                    setError(error);
                });
    
              }).catch(function(error) {
                // Handle error
                setLoading(false);
                setError(error);
              });
             // refresh token every 5 minutes  
            const interval = setInterval(() => {
                user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    func({subscriptionId,idToken }).then(result => {
                        tempresult.data.idToken = idToken;
                        setAppDefinition(tempresult.data);
                    }).catch(error => {
                    });        
                  }).catch(function(error) {
                    // Handle error
                  });
            }, 5 * 60 * 1000);
            return () => {
                clearInterval(interval);            
            }
		}
	}, [authContext, subscription, authUser]);
    React.useEffect(() => {
        if (!firebaseApp || !subscription) {
            setLoading(false);            
            return;
        }
        var subscriptionId = subscription ? subscription.id : null;

        const collection = firebaseApp.firestore().collection('subscriptions/' + subscriptionId + "/settings");
        const unsubscribe = collection.where("user", "==", userId).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                const data = snapshot.docs[0].data();
                setSettings({ ...data, id: snapshot.docs[0].id });
            }
            setLoading(false);
        }, (error) => {
            setError(error);
            setLoading(false);
        } );
        return () => unsubscribe();
    }, [userId, firebaseApp, subscription]);

	const saveSettings = async (data) => {
		if (firebaseApp) {
            var subscriptionId = subscription ? subscription.id : null;

            if (!subscriptionId) {                
                return;
            }
            const userId = authUser.user.uid;
			const collection = firebaseApp.firestore().collection('subscriptions/' + subscriptionId + "/settings");
			setLoading(true);
			if (settings) {
				// crud.updateItem(data.id, data);
				data.user = userId;
				await collection.doc(data.id).update(data);

				setSettings(data);
			}
			else {
				// crud.createItem(data);
				const id = (await (await collection.add(data)).get()).id;
				console.log("newdata", id);
				setSettings({ ...data, id: id, user: userId });
			}
			setLoading(false);
		}
	}

    return (
        
            <UserSettingsContext.Provider value={{ settings, saveSettings, loading, error,subscription,appDefinition }}>
                
                {children}
            </UserSettingsContext.Provider>
    );
}
// src/components/EditView.js
import React, { useState, useEffect } from 'react';
import FormField from './FormField';
import useCrud from '../hooks/useCrud';
import {  useNavigate, useParams } from 'react-router-dom';

// import { SubscriptionContext } from '@fireactjs/saas';
import { useContext } from 'react';
import { Checkbox } from '@mui/material';
import { UserSettingsContext } from '../UserSettingsContext';

const fieldHasAi = (field) => {
    return (field.aiPrompt || field.context || field.plugins);
}
const EditView = ({
    concept,
    item,

    onSubmit,
    mode = 'view',
    aiAssisted = true,
}) => {

    const navigate = useNavigate();
    // use params
    const { appDefinition } = useContext(UserSettingsContext);
    const id = useParams().id;
    const crud = useCrud(concept);
    var updateItem;
    if (mode !== 'new') {
        updateItem = crud.updateItem
        
    }
    if (!item) {
        item = crud.items.find((i) => i.id === id);
    }
    // console.log('item', crud.items);




    const [values, setValues] = useState(item || {});
    const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
    const [suggestions, setSuggestions] = useState([]);
    const [loading , setLoading] = useState(false);
    const [ifeellucky, setIfeellucky] = useState(true);
    const [processRevision, setProcessRevision] = useState(0);
    const [disabledFirstField, setDisabledFirstField] = useState(false);

    useEffect(() => {
        setValues(item || {});
    }, [item]);    

    const fetchSuggestions = async (field, context,fieldName,more = {}) => {

        // const rev = processRevision + 1;
        // setProcessRevision(rev);
        const fieldNames = Object.keys(concept.schema);
        const indexOfTheNextField = fieldNames.indexOf(fieldName) + 1;
        
        if(!fieldHasAi(field)) return;
        setLoading(true);
        try{
            const aiSuggestions = await appDefinition.functions.httpsCallable('backend-suggest', {timeout: 180000})({
                contextObject:context, field:fieldName,conceptName:concept.name, numberOfResponses:ifeellucky ? 1 : 3,size:384,temperature:ifeellucky ? 0.6:0.8,
                subscriptionId: crud.subscriptionId,
                idToken:appDefinition.idToken

            });
            // if(processRevision != rev) return;
            if(ifeellucky){
                const newValues = {...values,...more,[fieldName]:aiSuggestions.data[0]};
                // next field
                setValues(newValues);

                if (indexOfTheNextField < fieldNames.length) {
                    setCurrentFieldIndex(indexOfTheNextField);
                    // suggestions for next field
                    const contextObject = await populateReferencesRecursively(newValues,concept.schema);
                    // console.log("contextObject",contextObject)
                    fetchSuggestions(concept.schema[fieldNames[indexOfTheNextField]],contextObject, fieldNames[indexOfTheNextField],{...newValues});
                }
                else{
                    setLoading(false);
                    setValues(newValues);
                }
            }
            else{
                setSuggestions(aiSuggestions.data);
                setLoading(false);
            }
        }
        catch(e){
            console.log("error",e)
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchSuggestions = async (field, context,fieldName,more = {}) => {

            if(!fieldHasAi(field)) return;
            setLoading(true);
            try{
                const aiSuggestions = await appDefinition.functions.httpsCallable('backend-suggest', {timeout: 180000})({
                    contextObject:context, field:fieldName,conceptName:concept.name, numberOfResponses:ifeellucky ? 1 : 3,size:384,temperature:ifeellucky ? 0.6:0.8,
                    subscriptionId: crud.subscriptionId,
                    idToken:appDefinition.idToken
                });
                // if(processRevision != rev) return;
                
                    setSuggestions(aiSuggestions.data);
                    setLoading(false);
            }
            catch(e){
                console.log("error",e)
                setLoading(false);
            }
        };
        if(loading) return;
        if(mode === 'new' && currentFieldIndex === 0 && !disabledFirstField){
            const fieldNames = Object.keys(concept.schema);
            setDisabledFirstField(true);
            // only if the component is still mounted after 1 second
            const timer = setTimeout(() => {

                fetchSuggestions(concept.schema[fieldNames[0]],{}, fieldNames[0],{});
            }, 1000);
            return () => clearTimeout(timer);
        }    
    }, [  ]);
    const populateReferencesRecursively = async (newValue,schema) => {
        const contextObject = {};
        // populate references fields with actual values recursively
        const fieldNames = Object.keys(schema);
        for (let i = 0; i < fieldNames.length; i++) {
            const fieldName = fieldNames[i];
            const field = schema[fieldName];
            if (field.type === 'reference' || field.type === 'reflist') {

                var refType = field.reference;
                const refValue = newValue[fieldName];

                if(refType && refType.startsWith("${") && refType.endsWith("}")){
                    const path = refType.substring(2,refType.length-1);
                    const pathParts = path.split(".");
                    var currentObject = newValue;
                    var currentSchema = schema;
                    for(let i=0;i<pathParts.length;i++){
                        if(!currentObject) break;
                        const part = pathParts[i];
                        if(part === "this")
                            continue;
                        const fieldSchema = currentSchema[part];                        
                        currentObject = currentObject[part];
                        if (fieldSchema.type === 'reference' || fieldSchema.type === 'reflist') {
                            currentSchema = appDefinition.concepts.find(c=>c.name===fieldSchema.reference).schema;
                            if(fieldSchema.type === 'reflist'){
                                currentObject= await Promise.all(await Promise.all(currentObject.map(item => crud.collectionOfOtherConcept(fieldSchema.reference).doc(item).get()))).map(item=>item.data());                                
                            }
                            else
                                currentObject = await (await crud.collectionOfOtherConcept(fieldSchema.reference).doc(currentObject).get()).data();
                        }
                        
                        // resolve reference

                    }
                    refType = currentObject;
                }
                if (refValue) {
                    
                    const firebaseCollection = crud.collectionOfOtherConcept(refType);
                    if(field.type === 'reflist'){
                        var depth = 1;
                        var val = refValue;
                        if(typeof refValue === "string")
                            val = [refValue];
                        const refItems = [];
                        for(let i=0;i<val.length;i++){
                            const refItem = await firebaseCollection.doc(val[i]).get();
                            const item = { id: refItem.id, ...refItem.data() };
                            refItems.push(await populateReferencesRecursively(item,appDefinition.concepts.find(c=>c.name===refType).schema,depth-1));
                        }
                        contextObject[fieldName] = refItems;
                        continue;
                    }
                    const refItem = await firebaseCollection.doc(refValue).get();
                    const item = { id: refItem.id, ...refItem.data() };
                    contextObject[fieldName] = await populateReferencesRecursively(item,appDefinition.concepts.find(c=>c.name===refType).schema);
                }
            }
            else {
                contextObject[fieldName] = newValue[fieldName];
            }
        }
        return contextObject;
    };
    const handleChange = async (e, field, fieldName, idx) => {        
        const newValue = { ...values, [fieldName]: e.target.value };
        setDisabledFirstField(true);
        setValues(newValue);
        if(loading){
            return;   
        }
        // if (e.target.value ) { //mode === 'new' && 
            const indexOfTheNextField = fieldNames.indexOf(fieldName) + 1;
            if (indexOfTheNextField < fieldNames.length) {
                setCurrentFieldIndex(indexOfTheNextField);
                // suggestions for next field
                setLoading(true);                
                const contextObject = await populateReferencesRecursively(newValue,concept.schema);
                setLoading(false);
                // console.log("contextObject",contextObject)
                fetchSuggestions(concept.schema[fieldNames[indexOfTheNextField]],contextObject, fieldNames[indexOfTheNextField],newValue);

            }
    
        // }

    };
    const onCancel = () => {
        navigate(-1);
    };
    const handleSuggestionClick = (suggestion, field, fieldName, idx) => {
        handleChange({ target: { value: suggestion } }, field, fieldName, idx);
        setSuggestions([]);
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        if (updateItem) {
            updateItem(item.id, values);
            navigate(-1);

        }
        else {
            onSubmit(values);
        }
    };
    const schema = concept.schema;
    const fieldNames = Object.keys(schema);
    const isCurrentField = (field,fieldName,index) => {
        return (index === currentFieldIndex);            
    }
    return (
        <form onSubmit={handleSubmit}>
            
            <label htmlFor="ifeellucky">Autofill
            <Checkbox name='ifeelluck' checked={ifeellucky} onChange={(e)=>{setIfeellucky(e.target.checked)}}/></label>
            {fieldNames.map((fieldName, index) => {

                const field = schema[fieldName];
                return (

                    <div key={fieldName} style={{ display: (index <= currentFieldIndex || mode !== 'new') ? 'block' : 'none' }}>
                    {isCurrentField(field,fieldName,index) && (<CherryPick suggestions={suggestions} loading={loading} handleSuggestionClick={handleSuggestionClick} schema={schema} fieldNames={fieldNames} currentFieldIndex={currentFieldIndex} setSuggestions={setSuggestions} />)}

                        <FormField
                            value={values[fieldName]}
                            onChange={(e) => {
                                handleChange(e, field, fieldName, index)
                            }}
                            autofill={ifeellucky}
                            field={field}
                            fieldName={fieldName}
                            concept={concept}
                            mode={mode}
                            ownerObject={values}
                            loading={loading} setLoading={setLoading}
                        />

                    </div>
                );
            })}
            

            {mode !== 'view' && (
                <div>
                    <button type="submit" onClick={
                        (e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    } >
                        Submit
                    </button>
                    <button type="button" onClick={onCancel}>

                        Cancel
                    </button>
                </div>
            )}
        </form>
    );
};

export default EditView;

const CherryPick = ({suggestions, handleSuggestionClick, schema, fieldNames, currentFieldIndex, loading,ms})=> {
    ms = ms || 30000;
    const [timetoautoselect, setTimetoautoselect] = React.useState(-1);
    const [startTime, setStartTime] = React.useState(-1);
    const [timer, setTimer] = React.useState(-1);
    React.useEffect(() => {
        // whenever the suggestions change, scroll to the bottom of the list
        if(loading || suggestions.length === 0) return;
        // const suggestionsContainer = document.getElementById('suggestions');
        // suggestionsContainer.scrollTop = suggestionsContainer.scrollHeight;
        
        // set a visual timer for autoselecting the first suggestion
        if(timer !== -1) clearTimeout(timer);
        if(ms === -1) return;
        setTimetoautoselect(ms);
        setStartTime(Date.now());
        function handleTick(){            
            const timeLeft = ms - (Date.now() - startTime);
            if(timeLeft <= 0){
                setTimetoautoselect(0);
                setTimer(-1);
                handleSuggestionClick(suggestions[0], schema[fieldNames[currentFieldIndex]], fieldNames[currentFieldIndex], currentFieldIndex);
            }
            else{
                setTimetoautoselect(timeLeft);
                timer1 = setTimeout(handleTick, 100);
                setTimer(timer1);
            }
        }
        var timer1 = setTimeout(handleTick, 100);
        setTimer(timer1);
        return () => clearTimeout(timer1);
    }, [currentFieldIndex, fieldNames, handleSuggestionClick, loading, schema, startTime, suggestions,ms]);
    if( loading ) return (<div><img alt="loading" src="/Infinity-1s-200px.png"/></div>);
    if(suggestions.length === 0) return null;
    
    return <div>
        {suggestions.map((suggestion) => (
            <button
                key={suggestion}
                type="button" style={{ maxWidth: "368px", wordWrap: "break-word", height: "300px" }}
                onClick={() => {
                    if(timer !== -1)
                        clearTimeout(timer);

                    handleSuggestionClick(suggestion, schema[fieldNames[currentFieldIndex]], fieldNames[currentFieldIndex], currentFieldIndex)
                }}
            >
                <span>{(typeof (suggestion) === 'string' ? suggestion.split('\n') : suggestion).map(a => (
                    <p>{a}</p>
                ))}</span>
            </button>
        ))}
        <button type="button" onClick={() => handleSuggestionClick("", schema[fieldNames[currentFieldIndex]], fieldNames[currentFieldIndex], currentFieldIndex)}>
            None of the above
        </button>
        {timetoautoselect > 0 && <p>Autoselecting in {Math.round(timetoautoselect / 1000)} seconds</p> }
    </div>;
}

// src/hooks/useCrud.js
import { useState, useEffect,useContext } from 'react';
import { AuthContext,FireactContext,SetPageTitle  } from '@fireactjs/core';
import { SubscriptionContext } from '@fireactjs/saas';
import slugify from '../utils/slug';
import { UserSettingsContext } from '../UserSettingsContext';
function convertObject(object,appDefinition) {
    if(!object.fields){
        return {id: "static_" + slugify(object.name), name: object.name}
    }
    const staticItem = {...object.fields};
    staticItem.id = "static_" + slugify(object.name);
    staticItem.name = object.name;
    return staticItem;
}
function getStaticItems(appDefinition, ofType) {
    const staticItems = [];
    if(!appDefinition)
        return staticItems;
    const objects = appDefinition.objects;
    if(!objects)
        return staticItems;        

    objects.forEach(object => {
          if(!ofType || ofType === object.type){
              staticItems.push(convertObject(object,appDefinition));
          }
    })
    return staticItems;
}
const useCrud = (concept) => {
  const [items, setItems] = useState([]);
  const { firebaseApp } = useContext(AuthContext);
  const db = firebaseApp.firestore();
  const code = slugify(concept.name);
  const { appDefinition } = useContext(UserSettingsContext);
  const subs =  useContext(SubscriptionContext);
    var subscription = null;
    if(subs)
        subscription = subs.subscription;


        
  const collectionKey = `subscriptions/${subscription.id}/${code}`;
  const collection = db.collection(collectionKey)
  
  useEffect(() => {
    const unsubscribe = db.collection(collectionKey)
      .onSnapshot((snapshot) => {
        setItems([...getStaticItems(appDefinition, concept.name),...snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))]);
      });

    return () => unsubscribe();
  }, [concept, db,collectionKey,appDefinition]);

  const createItem = (data) => {
    return collection.add({ ...data });
  };

  const updateItem = (id, data) => {
    if(id.startsWith("static_"))
        return;
    return collection.doc(id).update(data);
  };

  const deleteItem = (id) => {
    if(id.startsWith("static_"))
        return;
    return collection.doc(id).delete();
  };

  const collectionOfOtherConcept = (conceptName) => {
    const code = slugify(conceptName);
    const collectionKey = `subscriptions/${subscription.id}/${code}`;
    const collection = db.collection(collectionKey)
    return collection;
  }



  return {
    collectionOfOtherConcept,
    items,
    createItem,
    updateItem,
    deleteItem,
    getStaticItems,
    subscriptionId: subscription.id
  };
};

export default useCrud;

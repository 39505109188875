import './App.css';
import firebaseConfig from "./firebaseConfig.json";
import { pathnames, AppTemplate, AuthContext, AuthProvider, AuthRoutes, MainMenu, PublicTemplate, ResetPassword, SignIn, SignUp, UserMenu, UserProfile, UserUpdateEmail, UserUpdateName, UserUpdatePassword, UserDelete, FireactProvider, ActionPages } from '@fireactjs/core';
import { BrowserRouter, Routes } from "react-router-dom";
import React, { useEffect } from 'react';
import { Route } from "react-router-dom";
import authMethods from "./authMethods.json";
import { CreateSubscription, ListSubscriptions, pathnames as subPathnames, PermissionRouter, Settings, SubscriptionMenu, ListUsers, SubscriptionProvider, ListInvoices, ManagePaymentMethods, ChangePlan, CancelSubscription, SubscriptionContext } from '@fireactjs/saas';
import SaaSConfig from './config.json';
import { useContext } from 'react';
import ListTemplates from './pages/ListTemplates';
import ListContacts from './pages/ListContacts';
import ListCampaigns from './pages/ListCampaigns';
import MinimalLayout from 'layout/MinimalLayout'

import Welcome from './pages/Welcome';
import { morePathNames } from './morePathNames';
import { ConceptRoutes, ConceptsLinks } from './components/ConceptRoutes';
// import AppDefinitionContext from './AppDefinitionContext';
import { ThemeProvider as ThemeProvider2,Collapse, createTheme, IconButton, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import UserSettings from './pages/UserSettings';
import { UserSettingsContext, UserSettingsProvider } from './UserSettingsContext';
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/loading/Loadable'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import ConfirmContextProvider from 'store/context/ConfirmContextProvider'
import { ReactFlowContext } from 'store/context/ReactFlowContext'
import { store } from 'store'


// routing
// import Routes from '../routes'

// defaultTheme
import themes from './themes'

// project imports
import NavigationScroll from './layout/NavigationScroll'
var icons = require("@mui/icons-material");
// const theme = createTheme({
// 	palette: {
// 		primary: {
// 			main: '#f44336'
// 		},
// 		secondary: {
// 			main: '#3EA6FF'
// 		},
// 		warning: {
// 			main: '#ffc107'

// }


// chatflows routing
const Chatflows = Loadable(lazy(() => import('views/chatflows')))

// marketplaces routing
const Marketplaces = Loadable(lazy(() => import('views/marketplaces')))

// apikey routing
const APIKey = Loadable(lazy(() => import('views/apikey')))

const Canvas = Loadable(lazy(() => import('views/canvas')))
const MarketplaceCanvas = Loadable(lazy(() => import('views/marketplaces/MarketplaceCanvas')))


const Brand = "Personoids";
const Logo = ({ size, color }) => {
	return (
		<img src={'/logo512.png'} alt="logo" className="logo" style={{ width: "100%", height: "100%" }} />
	);
}

const Loader = ({ size }) => {
	let cpSize = "35px";
	switch (size) {
		case "small":
			cpSize = "30px";
			break;
		case "medium":
			cpSize = "35px";
			break;
		case "large":
			cpSize = "45px";
			break;
		default:
			cpSize = "35px";
			break;
	}
	return (<img src="/Infinity-1s-200px.png" alt="loader" className="loader" />);
	// <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center"}}>
	// 	<CircularProgress color="warning" size={cpSize} />
	// 	<div style={{position: "absolute" }}>
	// 		<LocalFireDepartmentIcon size={size} />
	// 	</div>
	// </Box>
	// );
}

// const AppDefinitionProvider = ({ children }) => {
// 	const authContext = useContext(AuthContext);

// 	const [appDefinition, setAppDefinition] = React.useState(null);
// 	const [functions, setFunctions] = React.useState(null);
// 	const [loading, setLoading] = React.useState(true);
// 	const [error, setError] = React.useState(null);
// 	useEffect(() => {
// 		var firebaseApp = authContext ? authContext.firebaseApp : null;
// 		if (firebaseApp) {
// 			const CloudFunctions = firebaseApp.functions();
// 			setLoading(true);
// 			const func = CloudFunctions.httpsCallable('backend-getProjectDefinitions');
// 			const user = firebaseApp.auth().currentUser;
// 			// if(!user){
// 			// 	setFunctions(CloudFunctions);
// 			// 	return;
// 			// }
// 			setFunctions(CloudFunctions);
// 			setAppDefinition({
// 				functions: CloudFunctions,
// 				concepts:[],
// 			});
// 			return;
// 			func({}).then(result => {
// 				setLoading(false);
// 				result.data.functions = CloudFunctions;
// 				setFunctions(CloudFunctions);
// 				setAppDefinition(result.data);
// 			}).catch(error => {
// 				setLoading(false);
// 				setError(error);
// 			});
// 		}
// 	}, [authContext]);
// 	return (
// 		<AppDefinitionContext.Provider value={{ loading, error, appDefinition }}>
// 			{children}
// 		</AppDefinitionContext.Provider>
// 	);
// }

const SubscriptionMenuElement = ({ children }) => {

	const { appDefinition } = useContext(UserSettingsContext);

	const concepts = appDefinition ? appDefinition.concepts : null;
	const [flowiseOpen, setFlowiseOpen] = React.useState(false);

	const context = {}
	var oldNavLinks = [<NavLink key="templates" to={morePathNames.templates} activeClassName="active" className="nav-link" style={{ textDecoration: 'none' }}>
		<ListItemButton>
			<ListItemIcon></ListItemIcon>
			<ListItemText primary={
				<Typography color="textPrimary">
					Templates
				</Typography>
			} />
		</ListItemButton>
	</NavLink>,
	<NavLink key="contacts" to={morePathNames.contacts} activeClassName="active" className="nav-link" style={{ textDecoration: 'none' }}>
		<ListItemButton>
			<ListItemIcon></ListItemIcon>
			<ListItemText primary={
				<Typography color="textPrimary">
					Contacts
				</Typography>
			} />
		</ListItemButton>
	</NavLink>,
	<NavLink key="campaigns" to={morePathNames.campaigns} activeClassName="active" className="nav-link" style={{ textDecoration: 'none' }}>
		<ListItemButton>
			<ListItemIcon></ListItemIcon>
			<ListItemText primary={
				<Typography color="textPrimary">
					Campaigns
				</Typography>
			} />
		</ListItemButton>
	</NavLink>
	]
	if (!window.location.href.includes("toolkit=true")) {
		oldNavLinks = [];
	}
	const ExpandLessIcon = icons.ExpandLess;
	const ExpandMoreIcon = icons.ExpandMore;
	return (<SubscriptionMenu customItems={
		[<NavLink key="UserSettings" to={morePathNames.usersettings} style={{ textDecoration: 'none' }}>
		<ListItemButton>
			<ListItemIcon></ListItemIcon>
			<ListItemText primary={
				<Typography color="textPrimary">
					User Settings
				</Typography>
			} />
		</ListItemButton>
	</NavLink>,
	
	!(appDefinition && appDefinition.enableWelcome) ? <div key="chat"></div> : <a key="Chat" href="http://35.188.178.220/channel/general" style={{ textDecoration: 'none' }}  target='_blank'>
	<ListItemButton>
		<ListItemIcon></ListItemIcon>
		<ListItemText primary={
			<Typography color="textPrimary">
				Chat
			</Typography>
		} />
	</ListItemButton>
</a>,
	!(appDefinition && appDefinition.enableWelcome) ? <div key="team"></div> : <a key="Team" style={{ textDecoration: 'none' }} href="http://admin.muskaltech.organization.studio:3000/" target='_blank'>
	<ListItemButton>
		<ListItemIcon></ListItemIcon>
		<ListItemText primary={
			<Typography color="textPrimary">
				Team
			</Typography>
		} />
	</ListItemButton>
</a>,

		!(appDefinition && appDefinition.enableWelcome) ? <div key="sandbox"></div> : (<NavLink key="sandbox" to={'sandbox'} style={{ textDecoration: 'none' }}>
			<ListItemButton>
				<ListItemIcon></ListItemIcon>
				<ListItemText primary={
					<Typography color="textPrimary">
						Sandbox
					</Typography>
				} />
			</ListItemButton>
		</NavLink>),
		...oldNavLinks,

		!(appDefinition && appDefinition.enableFlowise) ? <div key="flow"></div> : (<div key="flow">
			<Typography variant="h6" color="textPrimary" sx={{ mt: 1, mb: 1 }}>
			<IconButton
				size="small"
				onClick={() => setFlowiseOpen(!flowiseOpen)}
				sx={{ ml: -0.5, mr: 1 }}
			>
				{(flowiseOpen) ?  <ExpandMoreIcon />:<ExpandLessIcon />}
			</IconButton>
				Flows
			</Typography>

			<Collapse key="Collapsable" in={flowiseOpen} timeout="auto" unmountOnExit>
				<NavLink to={`flowise`} style={{ textDecoration: 'none' }}>
                    <ListItemButton>
                        <ListItemIcon>
                            {React.createElement(icons['ViewList'])}
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary">
								Chatflows
                            </Typography>
                        } />
                    </ListItemButton>
                </NavLink>
				<NavLink to={`flowise/marketplaces`} style={{ textDecoration: 'none' }}>
                    <ListItemButton>
                        <ListItemIcon>
                            {React.createElement(icons[ 'ViewList'])}
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary">
								Marketplace
                            </Typography>
                        } />
                    </ListItemButton>
                </NavLink>
				<NavLink to={`flowise/apikey`} style={{ textDecoration: 'none' }}>
                    <ListItemButton>
                        <ListItemIcon>
                            {React.createElement(icons[ 'ViewList'])}
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary">
								APIKeys
                            </Typography>
                        } />
                    </ListItemButton>
                </NavLink>
			</Collapse>
		</div>)
			, ...ConceptsLinks({ context, concepts })]


	} />
	)
}
const BlankContainer = ({ children }) => {
	return (<div>{children}</div>);
}
function InnerApp() {
	// parse current url params for hideMenus
	const params = new URLSearchParams(window.location.search);
	const hideMenus = params.get('hideMenus') === "true";
	const blankElement = (<BlankContainer />);

	const customization = useSelector((state) => state.customization)

	return 	(<BrowserRouter>
<ThemeProvider2 theme={themes(customization)}>
	<ThemeProvider theme={themes(customization)}>
	<StyledEngineProvider injectFirst>
			
				<CssBaseline />
				<SnackbarProvider>
					<ConfirmContextProvider>
						
						<ReactFlowContext>
							<NavigationScroll>
								<Routes>
									<Route element={<AuthRoutes loader={<Loader size="large" />} />} >
										<Route element={<AppTemplate logo={<Logo size="large" />} toolBarMenu={hideMenus ? blankElement : <UserMenu />} drawerMenu={hideMenus ? blankElement : <MainMenu />} />}>

											<Route exact path={pathnames.ListSubscriptions} element={<ListSubscriptions loader={<Loader size="large" />} />} />
											<Route exact path={pathnames.CreateSubscription} element={<CreateSubscription />} />
											<Route exact path={pathnames.UserProfile} element={<UserProfile />} />
											<Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
											<Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
											<Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
											<Route exact path={pathnames.UserDelete} element={<UserDelete />} />

										</Route>
										<Route path={pathnames.Subscription} element={<SubscriptionProvider />} >
											<Route element={<SubscriptionProvider><UserSettings ></UserSettings></SubscriptionProvider>} >
												<Route  element={hideMenus ? <UserSettingsProvider></UserSettingsProvider> : <AppTemplate logo={<Logo size="large" />} toolBarMenu={hideMenus ? blankElement : <UserMenu />} drawerMenu={
													<UserSettingsProvider><SubscriptionMenuElement ></SubscriptionMenuElement></UserSettingsProvider>
												} />}  >


													<Route element={<PermissionRouter permissions={["access"]} />} >
														<Route exact path={pathnames.Subscription + "/"} element={
															<UserSettingsProvider>
																Welcome
																</UserSettingsProvider>} />
													</Route>
													<Route element={<PermissionRouter permissions={["admin"]} />} >
														<Route exact path={pathnames.Settings} element={<Settings loader={<Loader size="large" />} />} />
														<Route exact path={pathnames.ListUsers} element={<ListUsers loader={<Loader size="large" />} />} />
														<Route exact path={pathnames.ListInvoices} element={<ListInvoices loader={<Loader size="large" />} />} />
														<Route exact path={pathnames.ManagePaymentMethods} element={<ManagePaymentMethods loader={<Loader size="large" />} />} />
														<Route exact path={pathnames.ChangePlan} element={<ChangePlan />} />
														<Route exact path={pathnames.CancelSubscription} element={<CancelSubscription />} />
													</Route>
													<Route path={'sandbox'} element={
													<UserSettingsProvider>
													<Welcome loader={<Loader size="large" />} />
													</UserSettingsProvider>
													} />
													<Route path={morePathNames.templates} element={<ListTemplates loader={<Loader size="large" />} />} />
													<Route path={morePathNames.contacts} element={<ListContacts loader={<Loader size="large" />} />} />
													<Route path={morePathNames.campaigns} element={<ListCampaigns loader={<Loader size="large" />} />} />
													<Route path='flowise' element={<MinimalLayout/>}>
														<Route path='canvas' element={<Canvas />} />
														<Route path='canvas/:id' element={<Canvas />} />
														<Route path='marketplace/:id' element={<MarketplaceCanvas />} />																											
														<Route path='' element={<Chatflows />} />
														<Route path='marketplaces' element={<Marketplaces />} />
														<Route path='apikey' element={<APIKey />} />
													</Route>
													<Route path={morePathNames.usersettings} element={
														<UserSettingsProvider>

															<UserSettings loader={<Loader size="large" />} />
														</UserSettingsProvider>
													} />
													{ConceptRoutes({})}
												</Route>

											</Route>
										</Route>

									</Route>
									<Route element={<PublicTemplate />}>
										<Route path={pathnames.SignIn} element={
											<SignIn
												logo={<Logo size="large" />}
											/>
										} />
										<Route path={pathnames.SignUp} element={
											<SignUp
												logo={<Logo size="large" />}
											/>
										} />
										<Route path={pathnames.ResetPassword} element={
											<ResetPassword
												logo={<Logo size="large" />}
											/>
										} />
										<Route path={pathnames.ActionPages} element={
											<ActionPages
												logo={<Logo size="large" />}
											/>
										} />
									</Route>
								</Routes>
							</NavigationScroll>

						</ReactFlowContext>
					</ConfirmContextProvider>
				</SnackbarProvider>
		</StyledEngineProvider>
		</ThemeProvider>
		</ThemeProvider2>
		</BrowserRouter>
	)
} // end of innerApp

function App() {

	// merge pathnames
	for (var key in subPathnames) {
		pathnames[key] = subPathnames[key];
	}

	const config = {
		firebaseConfig: firebaseConfig,
		brand: Brand,
		pathnames: pathnames,
		authProviders: authMethods,
		saas: SaaSConfig
	}

	return (
		<FireactProvider config={config}>
			<AuthProvider firebaseConfig={firebaseConfig} brand={Brand}>
				{/* <AppDefinitionProvider> */}
					<Provider store={store}>
					<InnerApp />
					</Provider>
				{/* </AppDefinitionProvider> */}
			</AuthProvider>
		</FireactProvider>
	)
}

export default App;


// util for creating friendly strings for urls

export default function slugify ( string ) {
    return string
        .toString()
        .toLowerCase()
        .normalize( 'NFD' )
        .replace( /[\u0300-\u036f]/g, '' )
        .replace( /[^a-zA-Z0-9 ]/g, '' )
        .replace( /\s+/g, '-' )
        .replace( /-+/g, '-' )
        .replace( /(^-|-$)/, '' );
    }

import { AuthContext } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Checkbox, autocompleteClasses } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useCrud from "../hooks/useCrud";
import slugify from "../utils/slug";
import New from "./New";
import { UserSettingsContext } from "../UserSettingsContext";
const fieldHasAi = (field) => {
    return (field.aiPrompt || field.context);
}

const FormField = ({ field, value, onChange, fieldName,concept,ownerObject, loading, setLoading }) => {
    const autofill = false;
    const { appDefinition } = useContext(UserSettingsContext);
    const { firebaseApp } = useContext(AuthContext);
    const [innerValue, setInnerValue] = useState(value);
    const [refineValue, setRefineValue] = useState(value);
    // const [loading, setLoading] = useState(false);
    const subs =  useContext(SubscriptionContext);
    const crud = useCrud(concept);
    const [processRevision, setProcessRevision] = useState(0);

    var subscription = null;
    if(subs)
        subscription = subs.subscription;
    useEffect(() => {
        setInnerValue(value);
    }, [value]);
    const db = firebaseApp.firestore();
    // const [refName , setRefName] = useState(field.reference);
    // useEffect(() => {

    // }, [field.reference, refName]);
    var [fieldOptions, setFieldOptions] = useState([]);

    const [populatedObject, setPopulatedObject] = useState(null);
    useEffect(() => {
        var refName = field.reference;
        // if(field.reference !== refName){
        //     setRefName(field.reference);
        //     return;
        // }
        const populateReferencesRecursively = async (newValue,schema) => {
            const contextObject = {};
            // populate references fields with actual values recursively
            const fieldNames = Object.keys(schema);
            for (let i = 0; i < fieldNames.length; i++) {
                const fieldName = fieldNames[i];
                const field = schema[fieldName];
                if (field.type === 'reference' || field.type === 'reflist') {
    
                    var refType = field.reference;
                    const refValue = newValue[fieldName];
    
                    if(refType && refType.startsWith("${") && refType.endsWith("}")){
                        const path = refType.substring(2,refType.length-1);
                        const pathParts = path.split(".");
                        var currentObject = newValue;
                        var currentSchema = schema;
                        for(let i=0;i<pathParts.length;i++){
                            if(!currentObject) break;
                            const part = pathParts[i];
                            if(part === "this")
                                continue;
                            const fieldSchema = currentSchema[part];                        
                            currentObject = currentObject[part];
                            if (fieldSchema.type === 'reference' || fieldSchema.type === 'reflist') {
                                currentSchema = appDefinition.concepts.find(c=>c.name===fieldSchema.reference).schema;
                                if(fieldSchema.type === 'reflist'){
                                    currentObject= await Promise.all(await Promise.all(currentObject.map(item => crud.collectionOfOtherConcept(fieldSchema.reference).doc(item).get()))).map(item=>item.data());                                
                                }
                                else
                                    currentObject = await (await crud.collectionOfOtherConcept(fieldSchema.reference).doc(currentObject).get()).data();
                            }
                            
                            // resolve reference
    
                        }
                        refType = currentObject;
                    }
                    if (refValue) {
                        
                        const firebaseCollection = crud.collectionOfOtherConcept(refType);
                        if(field.type === 'reflist'){
                            var depth = 1;
                            var val = refValue;
                            if(typeof refValue === "string")
                                val = [refValue];
                            const refItems = [];
                            for(let i=0;i<val.length;i++){
                                const refItem = await firebaseCollection.doc(val[i]).get();
                                const item = { id: refItem.id, ...refItem.data() };
                                refItems.push(await populateReferencesRecursively(item,appDefinition.concepts.find(c=>c.name===refType).schema));
                            }
                            contextObject[fieldName] = refItems;
                            continue;
                        }
                        const refItem = await firebaseCollection.doc(refValue).get();
                        const item = { id: refItem.id, ...refItem.data() };
                        contextObject[fieldName] = await populateReferencesRecursively(item,appDefinition.concepts.find(c=>c.name===refType).schema);
                    }
                }
                else {
                    contextObject[fieldName] = newValue[fieldName];
                }
            }
            return contextObject;
        };
        populateReferencesRecursively(ownerObject,concept.schema).then((cpopulatedObject) => {
            // setPopulatedObject(cpopulatedObject);
            if(refName && refName.startsWith("${") && refName.endsWith("}")){
                const path = refName.substring(2,refName.length-1);
                const pathParts = path.split(".");
                var currentObject = cpopulatedObject;
                for(let i=0;i<pathParts.length;i++){
                    if(!currentObject) break;
                    const part = pathParts[i];
                    if(part === "this")
                        continue;
                    currentObject = currentObject[part];
                }
                refName = currentObject;
                // if(currentObject !== refName){
                //     setRefName(currentObject);
                //     return;
                // }
            }
            var refCode = refName ? slugify(refName) : null;

            const collectionKey = `subscriptions/${subscription.id}/${refCode}`;
            if(field.type === 'reference' || field.type === 'reflist'){
                const tempFieldOptions = [];
                const collection = db.collection(collectionKey)
                
                collection.get().then((snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        tempFieldOptions.push({value: doc.id, label: doc.data().name});
                    });
                        setFieldOptions([...crud.getStaticItems(appDefinition,refName).map(item=>({value:item.id,label:item.name}))
                            ,...tempFieldOptions]);
                });


            }
        }
        );

    
    }, [appDefinition,concept.schema,  db, field.reference, field.type,  subscription.id]);
    
    const populateReferencesRecursively = async (newValue,schema) => {
        const contextObject = {};
        // populate references fields with actual values recursively
        const fieldNames = Object.keys(schema);
        for (let i = 0; i < fieldNames.length; i++) {
            const fieldName = fieldNames[i];
            const field = schema[fieldName];
            if (field.type === 'reference' || field.type === 'reflist') {

                var refType = field.reference;
                const refValue = newValue[fieldName];

                if(refType && refType.startsWith("${") && refType.endsWith("}")){
                    const path = refType.substring(2,refType.length-1);
                    const pathParts = path.split(".");
                    var currentObject = newValue;
                    var currentSchema = schema;
                    for(let i=0;i<pathParts.length;i++){
                        if(!currentObject) break;
                        const part = pathParts[i];
                        if(part === "this")
                            continue;
                        const fieldSchema = currentSchema[part];                        
                        currentObject = currentObject[part];
                        if (fieldSchema.type === 'reference' || fieldSchema.type === 'reflist') {
                            currentSchema = appDefinition.concepts.find(c=>c.name===fieldSchema.reference).schema;
                            if(fieldSchema.type === 'reflist'){
                                currentObject= await Promise.all(await Promise.all(currentObject.map(item => crud.collectionOfOtherConcept(fieldSchema.reference).doc(item).get()))).map(item=>item.data());                                
                            }
                            else
                                currentObject = await (await crud.collectionOfOtherConcept(fieldSchema.reference).doc(currentObject).get()).data();
                        }
                        
                        // resolve reference

                    }
                    refType = currentObject;
                }
                if (refValue) {
                    
                    const firebaseCollection = crud.collectionOfOtherConcept(refType);
                    if(field.type === 'reflist'){
                        var val = refValue;
                        var depth = 1;
                        if(typeof refValue === "string")
                            val = [refValue];
                        const refItems = [];
                        for(let i=0;i<val.length;i++){
                            const refItem = await firebaseCollection.doc(val[i]).get();
                            const item = { id: refItem.id, ...refItem.data() };
                            refItems.push(await populateReferencesRecursively(item,appDefinition.concepts.find(c=>c.name===refType).schema));
                        }
                        contextObject[fieldName] = refItems;
                        continue;
                    }
                    const refItem = await firebaseCollection.doc(refValue).get();
                    const item = { id: refItem.id, ...refItem.data() };
                    contextObject[fieldName] = await populateReferencesRecursively(item,appDefinition.concepts.find(c=>c.name===refType).schema);
                }
            }
            else {
                contextObject[fieldName] = newValue[fieldName];
            }
        }
        return contextObject;
    };

    const fetchSuggestion = async (field, context,fieldName,refineCommand,currentRevision) => {
        console.log("fetchSuggestion",field,context,fieldName,refineCommand,value)
        // if(!fieldHasAi(field)) return;
        setLoading(true);
        try{
            const aiSuggestions = await appDefinition.functions.httpsCallable('backend-suggest', {timeout: 180000})({
                contextObject:context, field:fieldName,conceptName:concept.name, numberOfResponses:1,refineCommand,currentAnswer:value,size:512,
                subscriptionId: crud.subscriptionId,idToken:appDefinition.idToken
            });
            setInnerValue(aiSuggestions.data[0]);
            if(processRevision != currentRevision) return;
            onChange({target:{value:aiSuggestions.data[0]}}, field,fieldName);
            setLoading(false);

        }
        catch(e){
            console.log("error",e)
            setLoading(false);
        }
    };
    const handleInnerChange = (e) => {
        setInnerValue(e.target.value);
    }
	const handleChange = (e) => {
        // if (e.target.value === ownerObject[fieldName]) return;
	  onChange(e, field,fieldName);
	};
    var element = null;
    // useEffect(() => {

    const concepts = appDefinition ? appDefinition.concepts : [];

    // }, [field, db, refName, subscription.id, fieldOptions]);
    switch (field.type) {
        case 'string':            
        case 'text':
            element= (<SimpleTextField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange} autofill={autofill}/>);
            break;            
        case 'number':
                element= (<SimpleNumberField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange} autofill={autofill}/>);
            break;
        case 'date':
                element= (
                <input
                    type="date"
                    name={field.name}
                    value={value}
                    onChange={handleInnerChange}
                    onBlur={handleChange}
                    placeholder={field.label}
                />
            );
            break;
        case 'image':
                element= (
                    <ImageField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange}  autofill={autofill}/>
            );
            break;
            case 'audio':
                element= (
                    <div><input
                    type="text"
                    name={field.name}
                    value={innerValue}
                    onChange={handleInnerChange}
                    onBlur={handleChange}
                    placeholder={field.label}
                    /> <audio controls src= {innerValue} style={{maxWidth:100}}/></div>
            );
            break;
            case 'video':
                element= (
                    <div><input
                    type="text"
                    name={field.name}
                    value={innerValue}
                    onChange={handleInnerChange}
                    onBlur={handleChange}
                    placeholder={field.label}
                    /> <video controls src= {innerValue} style={{maxWidth:100}}/></div>
            );
            break;
        case 'link':
                element= (<SimpleLinkField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange}  autofill={autofill}/>);

                break;

        case 'boolean':
        case 'bool':
            element= (<SimpleBooleanField field={field} value={innerValue} handleInnerChange={handleInnerChange} handleChange={handleChange} autofill={autofill} />);
            break;
            case 'list':


                element= (
                    <SimpleListField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange} autofill={autofill} />
                );
                
            break;
        case 'concept reference':
            element= (                
                <ConceptReferenceField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange} fieldOptions={ fieldOptions} concepts={concepts} />
            );
            break;
        

        case 'reference':  
                  
            element= (
                <ReferenceField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange} fieldOptions={ fieldOptions} concepts={concepts}  />
            );
            break;

        case 'reflist':

            // should be able to view the current list, remove and select from a list of references for each item in the list
            element= (
                <ReferenceListField field={field} value={innerValue} handleChange={handleChange} handleInnerChange={handleInnerChange} fieldOptions={ fieldOptions} concepts={concepts} />
            );
            break;
        case 'action':
            element= (
                <ActionField field={field} value={innerValue} handleChange={handleChange} ownerObject={ownerObject} functions={appDefinition.functions} subscriptionId={crud.subscriptionId}/>
            );
        break;
                
	  default:
		element= null;
        break;
    }
    if(!element) return null;
          return <div><label htmlFor={field.name}>{field.label}</label>
          
            {element}
            {fieldHasAi(field)&& (<span>
                        <input style={{
                            width: '50%',
                            top: "-64px",
                            left: "10px",
                            position: "relative"}}
                        type="text" disabled={loading}
                        value={refineValue}
                        onChange={(e) => {
                            setRefineValue(e.target.value);
                        }}
                        onKeyDown={async (e) => {
                            if(e.key === 'Enter'){
                                e.preventDefault();
                                const contextObject = await populateReferencesRecursively(ownerObject,concept.schema);
                                console.log("contextObject",contextObject)
                                await fetchSuggestion(field,contextObject,fieldName,refineValue, value)
                                setRefineValue("");
                                
                                

                            }
                        }}

                        placeholder="Refine"
                    />
                    </span>)}
    </div>
    };


export default FormField;

const SimpleTextField = ({field, value, handleInnerChange, handleChange,autofill}) =>{
    return <div><textarea
        name={field.name}
        value={value}
        onChange={handleInnerChange}        
        multiline={true}
        placeholder={field.label} /> 
        {!autofill  && <button onClick={(e) => {
            e.preventDefault();            
            handleChange({target:{value}});
        }}>Next</button> }
        </div>;
}
const SimpleNumberField = ({field, value, handleInnerChange, handleChange,autofill}) =>{
    return <input
        type="number"
        name={field.name}
        value={value}
        onChange={handleInnerChange}
        onBlur={handleChange}
        placeholder={field.label} />;
}

const ImageField = ({field, value, handleInnerChange, handleChange,autofill}) =>{
    return <div><input
        type="text"
        name={field.name}
        value={value}
        onChange={handleInnerChange}
        onBlur={handleChange}
        placeholder={field.label} /> <img src={value} style={{ maxWidth: 100 }} /></div>;
}

const SimpleLinkField=({field, value, handleInnerChange, handleChange,autofill}) =>{
    return <div><input
        type="text"
        name={field.name}
        value={value}
        onChange={handleInnerChange}
        onBlur={handleChange}
        placeholder={field.label} /> <a href={value} target="_blank" rel='noreferrer'>Link</a></div>;
}

const SimpleBooleanField = ({field, value, handleChange, handleInnerChange,autofill}) =>{
    return <div><Checkbox
        name={field.name}
        checked={value}
        onChange={handleInnerChange}
        // onBlur={handleChange}
        placeholder={field.label} />
        {!autofill  && <button onClick={(e) => {
            e.preventDefault();
            handleChange({target:{value:value}});
        }}>Next</button>}
        </div>;
}

const SimpleListField = ({field, value, setInnerValue, handleChange,autofill})=> {
    if(typeof value === 'string')
    {
        value = value.split('\n');
    }
    return <div><textarea
        name={field.name}
        value={(value || []).filter(a => a.trim()).join('\n')}
        onChange={(e) => {
            
            const values = e.target.value.split('\n').filter(a => a.trim());
            setInnerValue(values);
            
            // onChange(e, field, fieldName, values);
        } }
        onBlur={(e) => {
            // const values = e.target.value.split('\n').filter(a=>a.trim());
            // setInnerValue(values);
            // handleChange(e);
            // onChange(e, field, fieldName, values);
        } }
        placeholder={field.label}         
        />
        {!autofill  && <button onClick={(e) => {
            e.preventDefault();
            handleChange({target:{value:value}});
        }}>Next</button>}
        </div>;
}

function ConceptReferenceField({field, value, handleChange,handleInnerChange, concepts, autofill}) {
    return <div>
        <select name={field.name} value={value} onChange={handleInnerChange} onBlur={handleInnerChange}>
        <option value="">{field.label}</option>
        {concepts.map((concept) => {
            return <option key={concept.name} value={concept.name}>{concept.name}</option>;
        })}
    </select>
    {!autofill  && <button onClick={(e) => {
        e.preventDefault();
        handleChange({target:{value:value}});
    }}>Next</button>}

     </div>;
}

function ReferenceListField({value, fieldOptions, handleChange, handleInnerChange,field, autofill,concepts}) {
    const [container, setContainer] = useState(null);
    if(typeof value === 'string')
    {
        value = value.split('\n').map(a=>a.trim()).filter(a=>a);
    }
    if(!value) value = [];
    return <ul>
        {(value || []).map((item) => {
            return <li key={item}>
                {(fieldOptions.find(a => a.value === item) || { label: "" }).label}
                <button onClick={() => {
                    const newValue = value.filter(a => a !== item);
                    // setInnerValue(newValue);
                    handleInnerChange({ target: { value: newValue } });
                    // onChange({target:{value:newValue}}, field,fieldName);
                } }>Remove</button>

            </li>;
        })}
        <li>
            <select name={field.name} value={""} onChange={(e) => {
                const newValue = [...value, e.target.value];
                if(e.target.value === 'new'){
                    // show New field
                    const conceptName = field.reference;
                    const concept = concepts.find(a => a.name === conceptName);
                    const element = <New concept={concept}  />;
                    
                    setContainer(element);

                }
                else
                    handleInnerChange({ target: { value: newValue } });
                // onChange({target:{value:newValue}}, field,fieldName);
            } } onBlur={(e) => {
                if (!e.target.value)
                    return;

                // const newValue = [...value, e.target.value];

                // handleInnerChange({ target: { value: newValue } });
            } }>
                <option value="">{field.label}</option>
                {fieldOptions.map((option) => {
                    return <option key={option.value} value={option.value}>{option.label}</option>;
                })}
                <option value="new">New "{field.label}"</option>
            </select>
        </li>
        <li>
            <button onClick={(e) => {
                e.preventDefault();                
                handleChange({ target: { value: value } });
            } }>Next</button>
        </li>
        {container}
    </ul>;
}

function ReferenceField({field, innerValue, handleInnerChange, handleChange, fieldOptions, autofill ,concepts}) {
    const [container, setContainer] = useState(null);

    return <div><select name={field.name} value={innerValue} onChange={(e) => {
        if(e.target.value === 'new'){
            // show New field
            const conceptName = field.reference;
            const concept = concepts.find(a => a.name === conceptName);
            const element = <New concept={concept} onSubmitFn={(newItem) => {
                //
                console.log(newItem);
            }} />;

            setContainer(element);
                }
        else{
            handleInnerChange({ target: { value: e.target.value } });
            handleChange( { target: { value: e.target.value } });
        }

    } }>
        <option value="">{field.label}</option>
        {fieldOptions.map((option) => {
            return <option key={option.value} value={option.value}>{option.label}</option>;
        })}
                        <option value="new">New "{field.label}"</option>

    </select>
    {!autofill  && <button onClick={(e) => {
        e.preventDefault();
        handleInnerChange({ target: { value: e.target.value } });
        handleChange({target:{value:e.target.value}});
    }}>Next</button>}
        {container}

    </div>;
}

function ActionField ({field, value,  ownerObject, functions,subscriptionId, autofill, handleChange,userId}) {
    const description = field.description;    
    return <div>
        <button onClick={async (e) => {
            e.preventDefault();
            const server_function = field.server_function;
            const parameters = field.parameters;
            // populate parameters
            const parameterValues = {};
            for (var pathIdx = 0; pathIdx < parameters.length; pathIdx++) {
                const path = parameters[pathIdx];
                parameterValues[path] = ownerObject[path];
            }
            const result = await functions.httpsCallable(server_function)({
                ...parameterValues,
                subscriptionId: subscriptionId,
                userId: userId
            });
            if (result.data.error) {
                alert(result.data.error);
            }
            else {
                handleChange({target:{value:result.data.value}});
            }
        } } 
        >{field.label}</button>
        <div className='resultsContainer'>
            {value && <div className='results'>{value}</div>}
        </div>
    </div>;
}

import React, { useContext, useState } from "react";
import { SubscriptionContext } from '@fireactjs/saas';
import "firebase/compat/functions";
import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { Paper, Box, Container, Grid, Button, Alert, Typography, FormControl, FormLabel, FormControlLabel, Checkbox } from "@mui/material";
import { doc, getFirestore, setDoc,deleteDoc } from 'firebase/firestore';

import {TextField } from "@mui/material";

export const UpdateContact = ({contact, setSelectedContact, setContacts}) => {

    const { subscription, setSubscription } = useContext(SubscriptionContext);
    const subscriptionName = subscription.name?subscription.name:"";

    const { config } = useContext(FireactContext);

    const [ processing, setProcessing ] = useState(false);

    const { firebaseApp } = useContext(AuthContext);

    const [ email, setEmail ] = useState(contact.email);
    const [ description, setDescription ] = useState(contact.description);
    const [ displayName, setDisplayName ] = useState(contact.displayName);

    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(false);

    return (
        <Container maxWidth="md">
            <SetPageTitle title={"Update Contact"+(subscriptionName!==""?(" - "+subscriptionName):"")} />
            <Paper>
                <Box p={2}>
                    <Typography component="h1" variant="h4" align="center">Update Contact</Typography>
                </Box>
                {error !== null &&
                    <Box p={2}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                }
                {success &&
                    <Box p={2}>
                        <Alert severity="success">The contact record has been successfully updated</Alert>
                    </Box>
                }
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField required fullWidth name="name" label="Name" type="text" margin="normal" onChange={(e) => {setDisplayName(e.target.value)}} value={displayName} />

                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField required fullWidth name="email" label="Email" type="email" margin="normal" onChange={(e) => {setEmail(e.target.value)}} value={email} />

                                    
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField multiline={true} required fullWidth name="description" label="Description" type="text" margin="normal" onChange={(e) => {setDescription(e.target.value)}} value={description} />

                                </FormControl>
                            </Box>
                        </Grid>

                    </Grid>

                </Box>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs>
                            <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => setSelectedContact(null)} >Back</Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" style={{marginRight: '10px'}} color="primary" variant="contained" disabled={processing} onClick={() => {
                                setProcessing(true);
                                setError(null);
                                setSuccess(false);
                                const db = firebaseApp.firestore();
                                

                                const docRef = db.doc('subscriptions/'+subscription.id+"/contacts/"+ contact.id);
                                // save 
                                docRef.set({
                                    email: email,
                                    displayName: displayName,
                                    description: description,
                                }).then(() => {
                                    setContacts(prevState => prevState.map(row => {
                                        if(row.id === contact.id){
                                            return {
                                                ...row, 
                                            }
                                        };
                                        return row;
                                    }));
                                    setSuccess(true);
                                    setProcessing(false);
                                }).catch(error => {
                                    setError(error.message);
                                    setProcessing(false)
                                });
                            }} >Save</Button>

                            <Button type="button" color="error" variant="contained" disabled={processing} onClick={() => {
                                setProcessing(true);
                                setError(null);
                                setSuccess(false);
                                const db = firebaseApp.firestore();
                                const docRef = db.doc('subscriptions/'+subscription.id+"/contacts/"+ contact.id);
                                // remove the contact 
                                docRef.delete().then(() => {
                                    setContacts(prevState => prevState.filter(row => row.id !== contact.id));
                                    setSelectedContact(null);
                                    setProcessing(false);
                                }).catch(error => {
                                    setError(error.message);
                                    setProcessing(false)
                                });

                            }} >Delete</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}
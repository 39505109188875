import { AuthContext } from '@fireactjs/core';
import { SubscriptionContext } from '@fireactjs/saas';
import { UserSettingsContext } from '../UserSettingsContext';
import useCrud from '../hooks/useCrud';
import {Triangle} from 'react-loader-spinner';
import { Slider } from '@mui/material';
var icons = require("@mui/icons-material");
// const completionSize = 1799;
const timeoutMS = 60 * 1000 * 5;
const React = require('react');



class Badger {
    constructor(options) {
      Object.assign(
        this, {
          backgroundColor: "#f00",
          color: "#fff",
          size: 0.6,      // 0..1 (Scale in respect to the favicon image size)
          position: "ne", // Position inside favicon "n", "e", "s", "w", "ne", "nw", "se", "sw"
          radius: 8,      // Border radius
          src: "",        // Favicon source (dafaults to the <link> icon href)
          onChange() {},
        },
        options
      );
      this.canvas = document.createElement("canvas");
      this.src = this.src || this.faviconEL.getAttribute("href");
      this.ctx = this.canvas.getContext("2d");
    }
  
    faviconEL = document.querySelector("link[rel$=icon]");
  
    _drawIcon() {
      this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
      this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
    }
  
    _drawShape() {
      const r = this.radius;
      const xa = this.offset.x;
      const ya = this.offset.y;
      const xb = this.offset.x + this.badgeSize;
      const yb = this.offset.y + this.badgeSize;
      this.ctx.beginPath();
      this.ctx.moveTo(xb - r, ya);
      this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
      this.ctx.lineTo(xb, yb - r);
      this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
      this.ctx.lineTo(xa + r, yb);
      this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
      this.ctx.lineTo(xa, ya + r);
      this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
      this.ctx.fillStyle = this.backgroundColor;
      this.ctx.fill();
      this.ctx.closePath();
    }
  
    _drawVal() {
      const margin = (this.badgeSize * 0.18) / 2;
      this.ctx.beginPath();
      this.ctx.textBaseline = "middle";
      this.ctx.textAlign = "center";
      this.ctx.font = `bold ${this.badgeSize * 0.82}px Arial`;
      this.ctx.fillStyle = this.color;
      this.ctx.fillText(this.value, this.badgeSize / 2 + this.offset.x, this.badgeSize / 2 + this.offset.y + margin);
      this.ctx.closePath();
    }
  
    _drawFavicon() {
      this.faviconEL.setAttribute("href", this.dataURL);
    }
  
    _draw() {
      this._drawIcon();
      if (this.value) this._drawShape();
      if (this.value) this._drawVal();
      this._drawFavicon();
    }
  
    _setup() {
      this.faviconSize = this.img.naturalWidth;
      this.badgeSize = this.faviconSize * this.size;
      this.canvas.width = this.faviconSize;
      this.canvas.height = this.faviconSize;
      const sd = this.faviconSize - this.badgeSize;
      const sd2 = sd / 2;
      this.offset = {
        n:  {x: sd2, y: 0 },
        e:  {x: sd, y: sd2},
        s:  {x: sd2, y: sd},
        w:  {x: 0, y: sd2},
        nw: {x: 0, y: 0},
        ne: {x: sd, y: 0},
        sw: {x: 0, y: sd},
        se: {x: sd, y: sd},
      }[this.position];
    }
  
    // Public functions / methods:
  
    update() {
      this._value = Math.min(99, parseInt(this._value, 10));
      if (this.img) {
        this._draw();
        if (this.onChange) this.onChange.call(this);
      } else {
        this.img = new Image();
        this.img.addEventListener("load", () => {
          this._setup();
          this._draw();
          if (this.onChange) this.onChange.call(this);
        });
        this.img.src = this.src;
      }
    }
  
    get dataURL() {
      return this.canvas.toDataURL();
    }
  
    get value() {
      return this._value;
    }
  
    set value(val) {
      this._value = val;
      this.update();
    }
  }


const Loader = ({ size ,sizePxl}) => {
    
    // parse hideMenu from url query params
    const url = window.location.href;    
    // part after http://sub.domain:port/...?hideMenu=true
    const hideMenu = url.includes('hideMenus=true');
    
    
    let cpSize = "35px";
    switch (size) {
        case "small":
            cpSize = "30px";
            break;
        case "medium":
            cpSize = "35px";
            break;
        case "large":
            cpSize = "45px";
            break;
        default:
            cpSize = "35px";
            break;
    }
    return (
        <div style={{width:"100%",
        alignItems:"center",justifyContent:"center",display:"flex"}}>
<Triangle
      height={sizePxl || "300"}
      width={sizePxl || "300"} 
      color="#1976d2"
      ariaLabel="triangle-loading"
      wrapperStyle={{alignItems:"center",justifyContent:"center",display:"flex"}}
      wrapperClassName=""
      visible={true}
    />      
      </div>
      );

    // <img src="/Infinity-1s-200px.png" alt="loader" className="loader" />);
    // <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center"}}>
    // 	<CircularProgress color="warning" size={cpSize} />
    // 	<div style={{position: "absolute" }}>
    // 		<LocalFireDepartmentIcon size={size} />
    // 	</div>
    // </Box>
    // );
}

const Collapsable = ({ children, openElement,closeElement, className}) => {
    const [open, setOpen] = React.useState(false);
    return (<div className={className + " collapsable"}>
        <div class="header" onClick={() => setOpen(!open)}>{open ? closeElement : openElement}</div>
        {open && children}
    </div>)
}

const concept = {
    name: "product specifications document"
}
const Component = ({loader}) => {

    const { firebaseApp } = React.useContext(AuthContext);
    const db = firebaseApp.firestore();
    const myBadgerOptions = {}; // See: constructor for customization options
    const myBadger = new Badger(myBadgerOptions);
    

    const spanRef = React.useRef(null);
    
    const [rawHtml , setRawHtml] = React.useState("");
    const userSettings = React.useContext(UserSettingsContext);

    const [page, setPage] = React.useState(0);
    const [input, setInput] = React.useState("Home");
    const [pageContents, setPageContents] = React.useState("");
    const [clicked, setClicked] = React.useState(false);
    const [history, setHistory] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedConcept, setSelectedConcept] = React.useState("");
    const [selectedConcept2, setSelectedConcept2] = React.useState("");
    const [entities,setEntities] = React.useState([]);
    const [completionSize, setCompletionSize] = React.useState(1799);
    const { subscription, settings, saveSettings } = React.useContext(UserSettingsContext);
    const subscriptionName = subscription.name ? subscription.name : "";
    const { items } = useCrud(selectedConcept || concept);
    const crud2 = useCrud(selectedConcept2 || concept);
    const items2 = crud2.items;

    // user name
    const user = firebaseApp.auth().currentUser;
    const userName = user.displayName ? user.displayName : "";

    const userDescription = settings && settings.user_details ? settings.user_details : "";
    const userRoleDescription = settings && settings.user_role_description ? settings.user_role_description : "";
    const orgDescription = settings && settings.org_details ? settings.org_details : "";
    const processesDescription = settings && settings.processes_details ? settings.processes_details : "";
    const mainInstructions = `You are a dynamic frontend rendering AI.
Your role is to output a snippet of a user interface in HTML that is tailored to the user's request and chain of actions. `
    const [instructions, setInstructions] = React.useState(`The UI should leverage generative AI to dynamically adjust the layout, content, and interactions based on real-time user behavior and feedback.
show it prefilled with realistic data and relevant actions(as buttons). it should be relevant and tailored to my processes and role.
include forms for data entry where needed (autofill data)
include many smart contextual in-place actions (as buttons or links) - with textual labels for the actions in the button (include the entire context in the button label)
lists must be clickable and show the details of the entry.     
include a 'data-context' attribute in each button/link that includes the entire context of the button/link (including the data for the entry if relevant)
you can use css3 , svg, d3, html , phaser , aframe (webvr) , webgl - assume they are all installed and ready to use in the page.
css should be embedded in the html and not in a separate answer.
always include long and descriptive image descriptions (in img alt attribute) for all images. (for example: "product image of a huggies newborn diaper, top angle" (or other relevant product) or "icon of <visualized concept>" - enough description for a blind person to understand the image).
action buttons should always be a part of an html form and act as a "submit".
include many img elements.
instead of answering that you can't do something, you should emulate an response.
if unsure, ask for clarification through a generated form.
You should only output the last html page snippet that corresponds to the user's last action.
only output in compact html snippets (not the full html).`);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [selectedItem2, setSelectedItem2] = React.useState(null);
    const [model, setModel] = React.useState("gpt-4");
    // on click, change the page so that the input goes to the bottom

    // date: ${new Date().toLocaleDateString()}
    // time of day: ${new Date().getHours() < 12 ? "morning" : new Date().getHours() < 18 ? "noon" : new Date().getHours() < 22 ? "evening" : "night"} 
    // user local: ${navigator.language}
    // user time zone: ${Intl.DateTimeFormat().resolvedOptions().timeZone}
    // user name: ${userName}
        
    function changeText(e) {
        e.preventDefault();
        setInput(e.target.value);
    }
    const [selectedObj, setSelectedObj] = React.useState(null);
    const [selectedObj2, setSelectedObj2] = React.useState(null);
    const [fullScreen, setFullScreen] = React.useState(false);
    const [temperature, setTemperature] = React.useState(0.0);
    const [notificationsCount, setNotificationsCount] = React.useState(1);

    const query = React.useCallback((request) => {
        // const response = await apiObj.api.chatComplete({ request: { prompt:chat, n: numberOfResponses, temperature: temperature || 1.0, completion: size || 256 } });
        // var instructions = "You are an API. Complete the following document:";
        var chat;
        const existingChat = history;
        if(existingChat && existingChat.length > 0){
            chat = existingChat;
            // remove old assistant responses (except the first one)
            // chat = chat.filter((item,i) => item.role !== "assistant" ); // && i !== 2
            // if(pageContents && pageContents.length > 20)
                // chat.push({ role: "assistant", content: pageContents });
        }else{
            function stringifyObject(obj){
                var str = "";
                if(obj){
                    Object.keys(obj).forEach(key => {
                        if(key == 'id')
                            return;
                        const label = key.replace(/_/g, " ");
                        const value = obj[key];
                        var valueStr = typeof value === "object" ? "\n"+stringifyObject(value) : value;
                        if(Array.isArray(value)){
                            valueStr = value.map(item => typeof item === "object" ? "\n"+stringifyObject(item) : item).join(", ");
                        }
                        str += `${label} : ${valueStr}\n`;
                    })
                }
                return str;
            }
            var fullInstructions;
            if( selectedObj){
                fullInstructions = `${mainInstructions}
Context:
${selectedConcept.name} : ${stringifyObject(selectedObj)}
${ selectedObj2 && `${selectedConcept2.name} : ${stringifyObject(selectedObj2)}`}
Instructions:
${instructions}`;
            }else{
                fullInstructions = `${mainInstructions}\n${instructions}`;
            }
            chat = [
                { role: "system", name: "system", content: fullInstructions },
                // { role: "user", name: "user", content: "say hello world" },
                // { role: "assistant", name: "assistant", content: "<p> hello world! </p>" },
                
                // {role: "assistant"}
            ]
        }
        chat.push({ role: "user", name: "user", content: request.trim() });
        return chat;
    }, [history, instructions, items,items2, pageContents, selectedObj,selectedObj2]);
    const appDefinition = userSettings.appDefinition;
    const concepts = appDefinition? appDefinition.concepts : [];
    
    const populateReferencesRecursively = React.useCallback(async (newValue,schema,depth =1) => {
        const contextObject = {};
        // populate references fields with actual values recursively
        if(depth == 0){
            return newValue;
        }
        const fieldNames = Object.keys(schema);
        for (let i = 0; i < fieldNames.length; i++) {
            const fieldName = fieldNames[i];
            const field = schema[fieldName];
            if (field.type === 'reference' || field.type === 'reflist') {

                var refType = field.reference;
                const refValue = newValue[fieldName];

                if(refType && refType.startsWith("${") && refType.endsWith("}")){
                    const path = refType.substring(2,refType.length-1);
                    const pathParts = path.split(".");
                    var currentObject = newValue;
                    var currentSchema = schema;
                    for(let i=0;i<pathParts.length;i++){
                        if(!currentObject) break;
                        const part = pathParts[i];
                        if(part === "this")
                            continue;
                        const fieldSchema = currentSchema[part];                        
                        currentObject = currentObject[part];
                        if (fieldSchema.type === 'reference' || fieldSchema.type === 'reflist') {
                            currentSchema = concepts.find(c=>c.name===fieldSchema.reference).schema;
                            if(fieldSchema.type === 'reflist'){
                                currentObject= await Promise.all(await Promise.all(currentObject.map(item => crud2.collectionOfOtherConcept(fieldSchema.reference).doc(item).get()))).map(item=>item.data());                                
                            }
                            else
                                currentObject = await (await crud2.collectionOfOtherConcept(fieldSchema.reference).doc(currentObject).get()).data();
                        }
                        
                        // resolve reference

                    }
                    refType = currentObject;
                }
                if (refValue) {
                    
                    const firebaseCollection = crud2.collectionOfOtherConcept(refType);
                    if(field.type === 'reflist'){
                        var val = refValue;
                        if(typeof refValue === "string")
                            val = [refValue];
                        const refItems = [];
                        for(let i=0;i<val.length;i++){
                            const refItem = await firebaseCollection.doc(val[i]).get();
                            const item = { id: refItem.id, ...refItem.data() };
                            refItems.push(await populateReferencesRecursively(item,concepts.find(c=>c.name===refType).schema,depth-1));
                        }
                        contextObject[fieldName] = refItems;
                        continue;
                    }
                    const refItem = await firebaseCollection.doc(refValue).get();
                    const item = { id: refItem.id, ...refItem.data() };
                    contextObject[fieldName] = await populateReferencesRecursively(item,concepts.find(c=>c.name===refType).schema,depth-1);
                }
            }
            else {
                contextObject[fieldName] = newValue[fieldName];
            }
        }
        return contextObject;
    }, [concepts]);    

    React.useEffect(() => {
        var selectedObject = items.find(a => a.id === selectedItem);
        if (selectedObject) {
            // populate the object
            const conceptDef = concepts.find(c=>c.name === selectedConcept.name);
            const schema = conceptDef.schema;
            setLoading(true);
            populateReferencesRecursively(selectedObject,schema).then((contextObject) => {
                setLoading(false);
                setSelectedObj(contextObject);
            });
            
        }
    }, [ selectedItem]);
    React.useEffect(() => {
        var selectedObject = items2.find(a => a.id === selectedItem2);
        if (selectedObject) {
            
            // populate the object
            const conceptDef = concepts.find(c=>c.name === selectedConcept.name);
            const schema = conceptDef.schema;
            setLoading(true);
            populateReferencesRecursively(selectedObject,schema).then((contextObject) => {
                setLoading(false);
                setSelectedObj2(contextObject);
            });

        }
    }, [ selectedItem2]);
    async function generateUIForTask2(input,retryCount =0){
        const chat=  query(input);
        setClicked(true);
        setPage(0);
        setLoading(true);
        setNotificationsCount(0);
        const uiForTasks = await userSettings.appDefinition.functions.httpsCallable('backend-generateUIForTask2', {timeout: timeoutMS})({
            chat,size:completionSize,
            subscriptionId: subscription.id,
            idToken:appDefinition.idToken,
            model:model,
            temperature: temperature,
            // size: model === "gpt-4" ? 2048 : 1024,
        }).catch((error) => {
            console.log("error", error);
            setPage(1);
            setLoading(false);
            // pop the last message
            chat.pop();
            setHistory(chat);
            if(error.message.trim() === "internal"){
                // try again.
                setPageContents("retrying...");
                if(retryCount < 3){
                    setTimeout(() => {
                        generateUIForTask2(input, retryCount+1);
                    }, 2000);
                }
                else{
                    setNotificationsCount(1);

                    setPageContents("too many retries. please try again later.");
                }
                return;
            }
            else{
                setNotificationsCount(1);

                setPageContents(error.message);
                return;
            }
        });
        if(!uiForTasks){
            return;
        }
        chat.push({ role: "assistant", name: "assistant", content: uiForTasks.data });
        setLoading(false);        
        setHistory(chat);
        setNotificationsCount(1);

        // console.log("uiForTasks", );
        setPageContents(uiForTasks.data);
        setPage(1);
        setInput("Home");
    }
    async function onClick(e) {
        e.preventDefault();
        if (input) {
            await generateUIForTask2(input);
        }
    }
    React.useEffect(() => {
        // myBadger.value = notificationsCount;
        if(notificationsCount == 1){
            document.title = 'Ready';
        }
        else if(notificationsCount == 0){
            document.title = 'Loading...';
        }
    }, [notificationsCount]);


    function htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    // parse the pageContents (raw html string) to get the react components
    React.useEffect(() => {
        const appDefinition = userSettings.appDefinition;
        if (spanRef.current) {
            // if pageContents contains ```html
            // then remove the ```html and cut the string to the next ```
            var actualContents = pageContents;
            if(pageContents.indexOf("```html") !== -1){
                actualContents = pageContents.substring(pageContents.indexOf("```html") + 7);
                actualContents = actualContents.substring(0, actualContents.indexOf("```"));
            }
            else if(pageContents.indexOf("```") !== -1){
                actualContents = pageContents.substring(pageContents.indexOf("```") + 3);
                actualContents = actualContents.substring(0, actualContents.indexOf("```"));
            }

            
            spanRef.current.innerHTML = actualContents;

            // process the html. replace images with text2image rendering prompts
            const images = spanRef.current.getElementsByTagName("img");
            for (let i = 0; i < images.length; i++) {
                const img = images[i];
                const src = img.getAttribute("src");
                const alt = img.getAttribute("alt");
                const title = img.getAttribute("title");
                var text = alt ? alt : title;
                if (src) {
                    if (!text) text = "web site image:";
                    const prompt = `${text} - ${src}`
                    const text2image = appDefinition.functions.httpsCallable('backend-text2image')({
                        prompt,size:512,
                        idToken:appDefinition.idToken,
                        subscriptionId: subscription.id
                    });
                    // const server = "https://ai-services.organization.studio";
                    text2image.then((result) => {
                        img.setAttribute("src", result.data);
                    }).catch((error) => {
                        console.log("error", error);
                    });
                }
            }
            // procees the html. extract the buttons and add click handlers
            const buttons = spanRef.current.getElementsByTagName("button");
            for (let i = 0; i < buttons.length; i++) {
                // skip if it is a submit button
                if (buttons[i].type === "submit") continue;

                // remove the button's onclick handler
                buttons[i].onclick = null;
                // add a click handler
                buttons[i].addEventListener("click", (e) => {
                    e.preventDefault();
                    setPage(0);
                    // name of the button
                    var newInput = e.target.innerText || e.target.textContent || e.target.value;
                    const context = e.target.getAttribute("data-context");
                    if (context) {
                        newInput = context + " - " + newInput;
                    }
                    // console.log("query", query);
                    if (newInput) {                    
                        generateUIForTask2("then user clicked:" + newInput).then((result) => {
                            // console.log("result", result);
                        });
                    }
                });
            }
            // process the html. extract the entities (className="entity") and entity types (data-type="entityType") and 
            // the entity-field classname to extract the actual data from sub elements of the entity. (with data-field-name attribute)
            const entitiesObjs = [];
            const entitiesElems = spanRef.current.getElementsByClassName("entity");
            for (let i = 0; i < entitiesElems.length; i++) {
                const entity = entitiesElems[i];
                const entityType = entity.getAttribute("data-type");
                const entityObj ={
                    type : entityType,                    
                }
                const entityFields = entity.getElementsByClassName("entity-field");
                for (let j = 0; j < entityFields.length; j++) {
                    const entityField = entityFields[j];
                    const fieldName = entityField.getAttribute("data-field-name");
                    const fieldValue = entityField.innerText || entityField.textContent || entityField.value;
                    entityObj[fieldName] = fieldValue;
                }

                entitiesObjs.push(entityObj);

            }
            setEntities(entitiesObjs);

            // process the html. extract the links and add click handlers
            const links = spanRef.current.getElementsByTagName("a");

            for (let i = 0; i < links.length; i++) {
                // make the links non navigable


                links[i].addEventListener("click", (e) => {
                    e.preventDefault();
                    setPage(0);
                    // name of the button
                    var newInput = e.target.innerText || e.target.textContent || e.target.value;
                    const context = e.target.getAttribute("data-context");
                    if (context) {
                        newInput = context + " - " + newInput;
                    }
                    newInput = newInput + " link " + e.target.getAttribute("_oldHref");

                    // console.log("query", query);
                    if (newInput) {
                        generateUIForTask2("then user clicked:" + newInput).then((result) => {
                            // console.log("result", result);
                        });
                        
                    }

                });
                links[i].setAttribute("_oldHref", links[i].href);
                links[i].href = "#" + links[i].href;

            }
            // process the html. extract the forms and add submit handlers that collect the data and send it to the backend using generateUIForTask2
            const forms = spanRef.current.getElementsByTagName("form");
            for (let i = 0; i < forms.length; i++) {
                forms[i].addEventListener("submit", (e) => {
                    e.preventDefault();
                    setPage(0);
                    // name of the button
                    // var newInput = e.target.innerText || e.target.textContent || e.target.value;
                    // console.log("query", query);
                    // if(newInput) {
                    var newInput = e.target.innerText || e.target.textContent || e.target.value;
                    const context = e.target.getAttribute("data-context");
                    if (context) {
                        newInput = context;
                    }

                    const formData = new FormData(e.target);
                    const data = Object.fromEntries(formData.entries());
                    
                    generateUIForTask2("submitted the form(" + newInput + ") with data " + JSON.stringify(data)).then((result) => {
                        // console.log("result", result);
                    });
                    // }

                });
            }
            // look for card-action divs and add click handlers
            const cardActions = spanRef.current.getElementsByClassName("card-action");
            for (let i = 0; i < cardActions.length; i++) {
                // remove the button's onclick handler
                cardActions[i].onclick = null;
                // add a click handler
                cardActions[i].addEventListener("click", (e) => {
                    e.preventDefault();
                    setPage(0);
                    // name of the button
                    var newInput = e.target.innerText || e.target.textContent || e.target.value;
                    const context = e.target.getAttribute("data-context");
                    if (context) {
                        newInput = context + " - " + newInput;
                    }

                    // console.log("query", query);
                    if (newInput) {
                        generateUIForTask2("then user clicked: " + newInput).then((result) => {
                            // console.log("result", result);
                        });
                    }
                });
            }
            // look for script elements and run them in 2 seconds
            const scripts = spanRef.current.getElementsByTagName("script");
            for (let i = 0; i < scripts.length; i++) {
                const script = scripts[i];
                const content = script.innerHTML;
                if (content) {
                    setTimeout(() => {
                        eval(content);
                    }, 2000);
                }
            }
            setRawHtml(spanRef.current.innerHTML);
        }
    }, [spanRef, pageContents, userSettings, query]);
    // if(pageContents){
    //     // console.log("pageContents", pageContents);
    //     contents2 = <div dangerouslySetInnerHTML={{ __html: htmlDecode(pageContents) }} />;
    //     console.log("c",contents)

    // }
    function onKeyDownForInput(e) {
        if (e.keyCode === 13) {
            // unless CTRL is pressed, prevent the default action
            if (!e.ctrlKey) {

                e.preventDefault();
                onClick(e);
            }
            else {
                // enter a new line in the textarea where the cursor is
                const start = e.target.selectionStart;
                const end = e.target.selectionEnd;
                const text = e.target.value;
                const before = text.substring(0, start);
                const after = text.substring(end, text.length);
                e.target.value = (before + "\n" + after);
                // move the cursor to the next line
                e.target.selectionStart = e.target.selectionEnd = start + 1;

            }
        }
    }
    
    console.log('entities',entities);
	const FullscreenExitIcon = icons.CloseFullscreen;
	const FullscreenIcon = icons.Fullscreen;
    const _loading = loading || (concepts && concepts.length === 0);
    return (<div className={"start-page" + (fullScreen ? " full-screen" : " partial-screen")}>
        <button className="small-button-at-upper-right" onClick={()=>{
            setFullScreen( !fullScreen );
        }}> {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}</button>
        <button className="small-button-at-upper-right" onClick={()=>{
            setFullScreen( !fullScreen );
        }}> {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}</button>

        <div className='mainpanel '>
        <div>
            {!fullScreen && "Results:"}
        </div>
            
            <div className={"content-container" + (page != 0 ? " fixedToBottom" : " normal")}>
                <span ref={spanRef} class="contents" />

            </div>
            {_loading && fullScreen && <Loader size="large" />}

        </div>
        {(<div className="user-input-panel panel">
            
            <textarea type="text" className={"user-input fixedToBottom"} onKeyDown={onKeyDownForInput} onChange={changeText} placeholder="Type what you want to do..." value={input} disabled={loading} />
            <button type="submit" className={"submit-button fixedToBottom"} onClick={onClick} disabled={loading}>
                {_loading ?
                        <Loader size="large" sizePxl={"150px"} />:                 
                 "GO"}
                </button>
                {
        <div className='advanced-panel '>
            <Collapsable className=""  closeElement={
                <h2 className="close-button">Advanced</h2>
            } openElement={ 
                <h2 className="open-button">Advanced</h2>
            }>
                        <div className="history">            
            <div className="history-text">
                {history.filter(a=>a.role === "user").map((item) => {                
                    return <div>
                        <a href="#" onClick={(e) => {
                            // go back to the page where the user clicked the link
                            e.preventDefault();                            
                            const chat = [...history.filter((a, i) => i < history.indexOf(item) ) ];
                            if(chat.length <= 1){
                                setPage(0);
                                setInput("Home");
                                setHistory([]);
                                return;
                            }
                            const lastResult = chat[chat.length-1].content;
                            setPageContents(lastResult);
                            setHistory(chat);
                            setInput(item.content);
                            // setPage(0);
                            // const uiForTasks = appDefinition.functions.httpsCallable('backend-generateUIForTask2')({
                            //     chat,
                            // subscriptionId: subscription.id,
                            // idToken:appDefinition.idToken
                            // });
                            // uiForTasks.then((result) => {
                            //     // console.log("result", result);
                            //     setPageContents(result.data);
                            //     setPage(1);
                            //     setClicked(false);
                            // });
                        }}>{item.content}</a>
                    </div>
                })}
            </div>
        </div>
            <div className="model">
                <h3 className="concept-title">Select Personoid</h3>
                <select className="concept-select" value={selectedConcept2.name} onChange={(e) => {
                    e.preventDefault();
                    const val = e.target.value || "gpt-4";
                    setModel(val);
                    if(val === "gpt-4")
                        setCompletionSize(2548);
                    else
                        setCompletionSize(512);
                    
                }}>
                    <option value="">Select</option>
                    <option value="gpt-4">Coffee Break</option>
                    <option value="gpt-3.5-turbo">Speedy Gonzales</option>
                </select>
            </div>
            <div className='slider-container'>
                <div className='slider-label'>Size</div>
                <Slider
                    value={completionSize}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={128} max={4096}
                    onChange={(e, value) => {
                        // console.log("value", value);
                        setCompletionSize(value);
                    }}
                />
            </div>
            <div className='slider-container'>
                <div className='slider-label'>Temperature</div>
                <Slider
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    value={temperature}
                    step={0.1}
                    min={0.0} max={2.0}
                    onChange={(e, value) => {
                        // console.log("value", value);
                        setTemperature(value);
                    }}
                />
            </div>

            {/* select concept */}
            <div className="concept">
                <h3 className="concept-title">Select Concept</h3>
                <select className="concept-select" value={selectedConcept.name} onChange={(e) => {
                    e.preventDefault();
                    setSelectedConcept({
                        name:e.target.value
                    });
                }}>
                    <option value="">Select</option>
                    {concepts && concepts.map((concept) => {    
                        return <option value={concept.name}>{concept.name}</option>
                    })}
                </select>
            </div>

            {/* for product specifications doc */}
            {selectedConcept && (<div className="product-specs">                
                <h3 className="product-specs-title">Select {selectedConcept.name}</h3>
                <select className="product-specs-select" value={selectedItem} onChange={(e) => {
                    e.preventDefault();
                    // if(e.target.value == ""){
                    //     return;
                    // }
                    setSelectedItem(e.target.value)
                    setHistory([]);
                }}>
                <option value="">Select</option>
                {items && items.map((item) => {
                    return <option value={item.id}>{item.name}</option>
                })}
                </select>

                 
            </div>)}
            {/* select concept */}
            <div className="concept">
                <h3 className="concept-title">Select Concept2</h3>
                <select className="concept-select" value={selectedConcept2.name} onChange={(e) => {
                    e.preventDefault();
                    setSelectedConcept2({
                        name:e.target.value
                    });
                }}>
                    <option value="">Select</option>
                    {concepts && concepts.map((concept) => {    
                        return <option value={concept.name}>{concept.name}</option>
                    })}
                </select>
            </div>

            {/* for product specifications doc */}
            {selectedConcept2 && (<div className="product-specs">                
                <div className="product-specs-title">Select {selectedConcept2.name}</div>
                <select className="product-specs-select" value={selectedItem2} onChange={(e) => {
                    e.preventDefault();
                    // if(e.target.value == ""){
                    //     return;
                    // }
                    setSelectedItem2(e.target.value)
                    setHistory([]);
                }}>
                <option value="">Select</option>
                {items && items2.map((item) => {
                    return <option value={item.id}>{item.name}</option>
                })}
                </select>

                 
            </div>)}
            {/* {(<Collapsable  closeElement={
                <h3 className="close-button">Hide History</h3>
            } openElement={
                <h3 className="open-button">History</h3>
            }> */}

        {/* </Collapsable> */}
        {/* )} */}

            {/* for instructions */}
            <Collapsable  closeElement={
                <h3 className="close-button">Hide Instructions</h3>
            } openElement={ 
                <h3 className="open-button">Instructions</h3>
            }>
            <div className="instructions">
                <textarea className="instruction-text" value={instructions} onChange={(e) => {
                    setInstructions(e.target.value)
                    setHistory([]);
                }
                } />
                </div>
                </Collapsable>

                <Collapsable  closeElement={
                <h3 className="close-button">Hide Source</h3>
            } openElement={
                <h3 className="open-button">View Source</h3>
            }>
                <pre className="source-text" >
                    {rawHtml}
                </pre>
        </Collapsable>

                </Collapsable>
                
        </div>}

        </div>)}
       
    </div>);
}

export default Component;
// src/components/List.js
import React, { useState } from 'react';
import useCrud from '../hooks/useCrud';
import { Route, NavLink } from 'react-router-dom';
import {ListItemButton, ListItemIcon, ListItemText,Typography} from '@mui/material';
import slugify from '../utils/slug';
var icons = require("@mui/icons-material");

const List = ({ concept }) => {
  const { items,  deleteItem } = useCrud(concept);
    console.log("concept",concept,items)
  return (
    <div>
      <h1>{concept.name}</h1>
      {(
            <div>
                <NavLink to={`new`} style={{textDecoration: 'none'}}>
                           <ListItemButton>

                            <ListItemText primary={
                                <Typography color="textPrimary">
                                    New {concept.name}
                                </Typography>
                            } />                  
                        </ListItemButton>
                </NavLink>
                <ul>
                    {items.map((item) => (
                        <li key={item.id}>
                            {item.icon && (<img src={item.icon} alt={item.name} className="icon" />)}
                            <span>{item.name}</span>
                            {!item.id.startsWith('static_') && <span><NavLink to={`${item.id}/edit`}  style={{textDecoration: 'none'}}> edit </NavLink>
                            <button onClick={() => deleteItem(item.id)}>Delete</button></span>}
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
    );
};

export default List;
import React, {useEffect,useContext,useState} from 'react';
import { AuthContext,FireactContext,SetPageTitle  } from '@fireactjs/core';
import {PaginationTable} from './PaginationTable'
import { SubscriptionContext } from '@fireactjs/saas';
// import { NavLink } from 'react-router-dom';
// import { morePathNames } from '../morePathNames';
import {UpdateContact} from './UpdateContact';
import AddContact from './AddContact';
import { Paper, Box, Container, Grid, Button, Avatar, Alert, Typography } from "@mui/material";

function Component ({loader}) {
  const {subscription} = useContext(SubscriptionContext);
  const subscriptionName = subscription.name?subscription.name:"";
  const { firebaseApp } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [addContactActive, setAddContactActive] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);  
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  const CloudFunctions = firebaseApp.functions();
  useEffect(() => {
    setLoaded(false);
    setError(null);
    // get default permission level name

    // let subscriptions = [];
    let tcontact = [];
    const campaignsRef = firebaseApp.firestore().collection('subscriptions/'+subscription.id+"/campaigns");
    const campaignsQuery = campaignsRef; //.where('subscription', '==', subscription.id);
    Promise.all([campaignsQuery.get()]).then(([tSnapshot]) => {

        tSnapshot.forEach(record => {
          tcontact.push({
                id: record.id,
                sender: record.data().sender,
                displayName: record.data().displayName || "Untitled",
                description: record.data().description || "",
                email: record.data().email,
                actionCol:<Button size="small" variant="outlined" onClick={() => setSelectedContact({
                  id: record.id,
                  email: record.data().email,
                  displayName: record.data().displayName || "Untitled",
                  description: record.data().description || "",
              })}>Update</Button>
            })
        })
      setRows(tcontact);
      setTotal(tcontact.length);

          if(addContactActive === false && selectedContact === null){
            window.scrollTo(0, 0);
        }
        setCampaigns(tcontact);
        setLoaded(true);
    }).catch(error => {
        setLoaded(true);
        setError(error.message);
    })
},[addContactActive, firebaseApp,  selectedContact, subscription]);

return (
  <>
      <SetPageTitle title={"User List"+(subscriptionName!==""?(" - "+subscriptionName):"")} />
      {loaded?(
          <>
          {selectedContact !== null?(
              <UpdateContact  contact={selectedContact} setSelectedContact={setSelectedContact} setCampaigns={setCampaigns} />
          ):(
              <>
              {addContactActive?(
                  <AddContact setAddContactActive={setAddContactActive} setCampaigns={setCampaigns} />
              ):(
                  <Container maxWidth="lx">
                      {error !== null?(
                          <Alert severity="error">{error}</Alert>
                      ):(
                          <Paper>
                              <Box p={2}>
                                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                      <Grid item>
                                          <Typography component="h1" variant="h4">Contact List</Typography>
                                      </Grid>
                                      <Grid item>
                                      <Button variant="contained" onClick={() => setAddContactActive(true)}>Add Contact</Button>
                                      <Button variant="outlined" onClick={() => setAddContactActive(true)}>Import Campaigns</Button>
                                      </Grid>
                                  </Grid>
                              </Box>
                              <Box p={2}>
                                  <PaginationTable columns={[
                                      {name: "Name", field: "displayName", style: {width: '30%'}},
                                      {name: "Email", field: "email", style: {width: '35%'}},
                                      {name: "Action", field: "actionCol", style: {width: '15%'}}
                                  ]}
                                  rows={rows}
                                  totalRows={total}
                                  pageSize={pageSize}
                                  page={page}
                                  handlePageChane={(e, p) => {
                                      setPage(p);
                                  }}
                                  handlePageSizeChange={(e) => {
                                      setPage(0);
                                      setPageSize(e.target.value);
                                  }}
                                  />
                              </Box>
                          </Paper>
                      )}
                  </Container>
              )}
              </>
          )}
          </>
      ):(
          <>{loader}</>
      )}
  </>
)
}

export default Component;
// src/components/ConceptRoutes.js
import React, { useEffect } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Collapse, IconButton, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import EditView from './EditView';
import List from './List';
import slugify from '../utils/slug';
import New from './New';
import { AuthContext } from '@fireactjs/core';
import { UserSettingsContext, UserSettingsProvider } from '../UserSettingsContext';

var icons = require("@mui/icons-material");

const ConceptRoutes = ({}) => {
    // parse url
    const authContext = React.useContext(AuthContext);
    const url = window.location.href;
    const urlParts = url.split('/');
    // part after http://sub.domain:port/sub/subid/...
    const subId = urlParts[4];

    var firebaseApp = authContext ? authContext.firebaseApp : null;
    var CloudFunctions;
    if (firebaseApp) {
        CloudFunctions = firebaseApp.functions();
    }
    const [concepts, setConcepts] = React.useState([]);

    useEffect(() => {
        if(subId){
            // fetch concepts from appDefinition
            const user = firebaseApp.auth().currentUser;
            const func = CloudFunctions.httpsCallable('backend-getProjectDefinitions');
            if(!user)
                return;
            user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                func({subscriptionId: subId,
                    idToken:idToken }).then(result => {
                    // concepts = result.data.concepts;
                    setConcepts(result.data.concepts);
                }).catch(error => {
                    console.log(error);
                });
    
              })
            
            // const { appDefinition } = userSettings;
            // concepts = appDefinition ? appDefinition.concepts : null;
        }
    }, [CloudFunctions,authContext]);
    if (!concepts || concepts.length === 0) return [];
    return concepts.map((concept) => {
        const code = slugify(concept.name);

        return [
            <Route

                path={`${code}`}
                exact
                element={
                    <UserSettingsProvider>
                <List concept={concept} />
                </UserSettingsProvider>
                }

            />,
            <Route

                path={`${code}/:id`}
                exact
                element={
                    <UserSettingsProvider>
                <EditView concept={concept} mode={'view'} />
                </UserSettingsProvider>
                }


            />,
            <Route

                path={`${code}/:id/edit`}
                exact
                element={
                    <UserSettingsProvider>
                <EditView concept={concept} mode={'edit'} />
                </UserSettingsProvider>
                } />,
            <Route
                key={code}
                path={`${code}/new`}
                exact
                element={
                    <UserSettingsProvider>
                <New concept={concept} />
                </UserSettingsProvider>
                } />

        ];

    });
};

const capitalizeWords = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

const ExpandLessIcon = icons.ExpandLess;
const ExpandMoreIcon = icons.ExpandMore;

const ConceptsLinks = ({ context, concepts}) => {
    const [open, setOpen] = React.useState("");
	const { settings } = React.useContext(UserSettingsContext) || {};
	var enabled_packages = [];
	if (settings) {
		enabled_packages = settings.enabled_packages ? settings.enabled_packages : [];
	}
	if (enabled_packages.length === 0) {
		enabled_packages = [];
	}
    enabled_packages = false;
    if (!concepts) return [];
    // group by part_of_packages of the concept
    const groupedConcepts = concepts.reduce((acc, concept) => {
        const partOfPackage = concept.part_of_packages || ['Other'];
        for (let i = 0; i < partOfPackage.length; i++) {
            const p = partOfPackage[i];
            if(enabled_packages && !enabled_packages.includes(p))
                continue;
            if (!acc[p]) {
                acc[p] = [];
            }
            acc[p].push(concept);
    
        }
        return acc;
    }, {});
    const packages = Object.keys(groupedConcepts);

    const result = packages.map((_package) => {
        const concepts = groupedConcepts[_package];
        const subMenu =  concepts.filter(c => c.crudPages).map((concept) => {
            const code = slugify(concept.name);

            return (
                <NavLink to={`${code}`} style={{ textDecoration: 'none' }}>
                    <ListItemButton>
                        <ListItemIcon>
                            {React.createElement(icons[concept.icon || 'ViewList'])}
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary">
                                {capitalizeWords(concept.name)}
                            </Typography>
                        } />
                    </ListItemButton>
                </NavLink>
            );
        })
        
        return (
                    <div>
                        <Typography variant="h6" color="textPrimary" sx={{ mt: 1, mb: 1 }}>
                        <IconButton
                            size="small"
                            onClick={() => setOpen(open === _package ? "" : _package)}
                            sx={{ ml: -0.5, mr: 1 }}
                        >
                            {(open === _package) ?  <ExpandMoreIcon />:<ExpandLessIcon />}
                        </IconButton>
                            {_package}
                        </Typography>
  
                        <Collapse in={open === _package} timeout="auto" unmountOnExit>
                            {subMenu}
                        </Collapse>
                    </div>
                );
        
    }
    );
    return result;
};

export { ConceptRoutes, ConceptsLinks }
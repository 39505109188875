export const morePathNames = {
	"templates": "templates",
	"template": "templates/:id",
	"editTemplate": "templates/:id/edit",
	"contacts": "contacts",
	"contact": "contacts/:id",
	"editContact": "contacts/:id/edit",
	"addTemplate": "templates/add",
	"addContact": "contacts/add",
	"campaigns": "campaigns",
	"_add": "add",
	"usersettings": "usersettings",
};

// src/components/New.js
import React from 'react';
import EditView from './EditView';
import useCrud from '../hooks/useCrud';
import { Route, NavLink,useNavigate } from 'react-router-dom';
import { SubscriptionContext } from '@fireactjs/saas';


const New = ({ concept, onSubmitFn}) => {
    const subs =  React.useContext(SubscriptionContext);
    var subscription = null;

    subscription = subs.subscription;

    const { createItem} = useCrud(concept, subscription.subscriptionId);
	  const navigate = useNavigate();

    const onSubmit = (values) => {
        if(!values.name)
          return;
        createItem(values);
        
        if(onSubmitFn)
            onSubmitFn(values);
        else
          navigate(-1);
    };
    const onCancel = () => {
      if(onSubmitFn)
        onSubmitFn(null);
      else
        navigate(-1);
    };

  return (
    <EditView
      concept={concept}
      onSubmit={onSubmit}
      onCancel={onCancel}
      mode="new"
    />
  );
};

export default New;

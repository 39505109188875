
import React, { useContext, useState,useEffect } from "react";
import { SubscriptionContext } from '@fireactjs/saas';
import "firebase/compat/functions";
import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { Paper, Box, Container, Grid, Button, Alert, Typography, FormControl, FormLabel, FormControlLabel, Checkbox,
    InputLabel, Select, 
    MenuItem,
    TextField,
    InputAdornment,
    IconButton,
    CircularProgress,
    // dropdown 
    Autocomplete,

 } from "@mui/material";
import { doc, getFirestore, setDoc,deleteDoc } from 'firebase/firestore';

export const UpdateTemplate = ({template, setSelectedTemplate, setTemplates}) => {

    const { subscription, setSubscription } = useContext(SubscriptionContext);
    const subscriptionName = subscription.name?subscription.name:"";

    const { config } = useContext(FireactContext);

    const [ processing, setProcessing ] = useState(false);

    const { firebaseApp } = useContext(AuthContext);
    const CloudFunctions = firebaseApp.functions();

    const [ description, setDescription ] = useState(template.description);
    const [ displayName, setDisplayName ] = useState(template.displayName);
    const [ content, setContent ] = useState(template.content);
    const [contacts, setContacts] = useState([]);
    const [contact, setContact] = useState(null);

    const [ error, setError ] = useState(null);
    const [testResults, setTestResults] = useState(null);
    const [ success, setSuccess ] = useState(false);
    useEffect(() => {
        setError(null);
        // get default permission level name
    
        // let subscriptions = [];
        let tcontact = [];
        const contactsRef = firebaseApp.firestore().collection('subscriptions/'+subscription.id+"/contacts");
        const contactsQuery = contactsRef; //.where('subscription', '==', subscription.id);
        Promise.all([contactsQuery.get()]).then(([tSnapshot]) => {
    
            tSnapshot.forEach(record => {
              tcontact.push({
                    id: record.id,
                    displayName: record.data().displayName || "Untitled",
                    
                })
            })
    
            setContacts(tcontact);
        }).catch(error => {
            setError(error.message);
        })
    },[ firebaseApp,subscription]);

    return (
        <Container maxWidth="md">
            <SetPageTitle title={"Update Template"+(subscriptionName!==""?(" - "+subscriptionName):"")} />
            <Paper>
                <Box p={2}>
                    <Typography component="h1" variant="h4" align="center">Update Template</Typography>
                </Box>
                {error !== null &&
                    <Box p={2}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                }
                {success &&
                    <Box p={2}>
                        <Alert severity="success">The template record has been successfully updated</Alert>
                    </Box>
                }
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField required fullWidth name="name" label="Name" type="text" margin="normal" onChange={(e) => {setDisplayName(e.target.value)}} value={displayName} />

                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField required multiline={true} fullWidth name="description" label="Description" type="text" margin="normal" onChange={(e) => {setDescription(e.target.value)}} value={description} />

                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField required multiline={true} fullWidth name="content" label="Content" type="text" margin="normal" onChange={(e) => {setContent(e.target.value)}} value={content} />

                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box p={2}>
                                <FormControl fullWidth>
                                    {/* select of a single contacts from all contacts */}
                                    <Autocomplete
                                        id="contacts"
                                        options={contacts}
                                        getOptionLabel={(option) => option.displayName}
                                        style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Test Contact" variant="outlined" 
                                         
                                        />}
                                        onChange={(event, newValue) => {
                                            setContact(newValue);
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs>
                            <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => setSelectedTemplate(null)} >Back</Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" style={{marginRight: '10px'}} color="primary" variant="contained" disabled={processing} onClick={() => {
                                setProcessing(true);
                                setError(null);
                                setSuccess(false);
                                const db = firebaseApp.firestore();
                                const docRef = db.doc('subscriptions/'+subscription.id+"/templates/"+ template.id);
                                // save 
                                
                                docRef.set({
                                    displayName: displayName,
                                    description: description,
                                    content: content,
                                }).then(() => {
                                    setTemplates(prevState => prevState.map(row => {
                                        if(row.id === template.id){
                                            return {
                                                ...row, 
                                            }
                                        };
                                        return row;
                                    }));
                                    setSuccess(true);
                                    setProcessing(false);
                                }).catch(error => {
                                    setError(error.message);
                                    setProcessing(false)
                                });
                            }} >Save</Button>

<Button type="button" color="error" variant="contained" disabled={processing} onClick={() => {
                                setProcessing(true);
                                setError(null);
                                setSuccess(false);
                                const db = firebaseApp.firestore();
                                const docRef = db.doc('subscriptions/'+subscription.id+"/templates/"+ template.id);
                                                                // remove the template 
                                docRef.delete().then(() => {
                                    setTemplates(prevState => prevState.filter(row => row.id !== template.id));
                                    setSelectedTemplate(null);
                                    setProcessing(false);
                                }).catch(error => {
                                    setError(error.message);
                                    setProcessing(false)
                                });

                            }} >Delete</Button>



<Button type="button" color="secondary" variant="contained" disabled={!contact} onClick={() => {
                                setProcessing(true);
                                setError(null);
                                setSuccess(false);
                                const db = firebaseApp.firestore();
                                const collection = db.collection('subscriptions/'+subscription.id+"/contacts");
                                const contactByIdRef = collection.doc(contact.id);
                                contactByIdRef.get().then((doc) => {
                                    const firstContact = doc.data();
                                    console.log("contact:",firstContact)
                                    // const { displayName, description, content } = template;
                                    const contactDisplayName = firstContact.displayName;
                                    const contactEmail = firstContact.email;
                                    const contactId = firstContact.id;
                                    const contactDescription = firstContact.description;
                                    const func = CloudFunctions.httpsCallable('renderTemplate-test');
                                    console.log("rendering test:",content, contactDisplayName, contactDescription);
                                    return func({content, contactDisplayName, contactDescription}).then(result => {
                                        console.log("result:",result); 
                                        setTestResults(result.data);
                                        setProcessing(false)
                                    });
                                
                                }).catch(error => {
                                    setError(error.message);
                                    setProcessing(false)
                                });

                                

                            }} >Test</Button>
                        </Grid>
                    </Grid>
                </Box>
                {testResults && 
                <Box p={2}>
                    <Typography component="h2" variant="h6" align="center">Test Results</Typography>
                    <p>
                        {testResults.choices[0].split('\n').map((item, key) => {
                            return <p key={key}>{item}<br/></p>
                            })}
                        </p>
                        
                </Box>}
            </Paper>
        </Container>
    )
}
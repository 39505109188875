import React, {useEffect,useContext,useState} from 'react';
import { AuthContext,FireactContext,SetPageTitle  } from '@fireactjs/core';

// import { NavLink } from 'react-router-dom';
// import { morePathNames } from '../morePathNames';
import { Paper, Box, Container, Grid, Button, Avatar, Alert, Typography } from "@mui/material";
import {UserSettingsContext} from '../UserSettingsContext';

function Component ({loader}) {
//   const {} = useContext(SubscriptionContext) ;
  
  const {subscription,settings,saveSettings} = useContext(UserSettingsContext) ;
  const subscriptionName = subscription.name?subscription.name:"";
const { appDefinition } = useContext(UserSettingsContext);
const { firebaseApp } = useContext(AuthContext);
const userDescription = settings && settings.user_details?settings.user_details:"";
const userRoleDescription = settings && settings.user_role_description?settings.user_role_description:"";
const orgDescription = settings && settings.org_details?settings.org_details:"";
const processesDescription = settings && settings.processes_details?settings.processes_details:"";

// user name
const user = firebaseApp.auth().currentUser;
const userName = user.displayName?user.displayName:"";

const [internalUserDescription,setInternalUserDescription] = React.useState(userDescription);
const [internalUserRoleDescription,setInternalUserRoleDescription] = React.useState(userRoleDescription);
const [internalOrgDescription,setInternalOrgDescription] = React.useState(orgDescription);
const [internalProcessesDescription,setInternalProcessesDescription] = React.useState(processesDescription);

useEffect(() => {
    if(!appDefinition || !subscription)
        return;
    // settings page has a list of enabled packages
    
    // update the internal states
    setInternalUserDescription(userDescription);
    setInternalUserRoleDescription(userRoleDescription);
    setInternalOrgDescription(orgDescription);
    setInternalProcessesDescription(processesDescription);
},[appDefinition, subscription, settings, userDescription, userRoleDescription, orgDescription, processesDescription])

if(!appDefinition || !subscription)
    return null;
// settings page has a list of enabled packages
var  potentialPackages = ["Other"];
appDefinition.concepts.forEach(concept => {
    if(concept.part_of_packages){
        concept.part_of_packages.forEach(packageName => {
            if(!potentialPackages.includes(packageName)){
                potentialPackages.push(packageName);
            }
        })
    }
})
potentialPackages = [...new Set(potentialPackages)];
const enabledPackages = settings && settings.enabled_packages?settings.enabled_packages:[];



return (
  <>
    <SetPageTitle title="Settings" />
    <Container maxWidth="lg">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Typography variant="h5">Settings</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {/* editable text area fields for: */}

                {/* user description */}
                <Paper>
                    <Box p={2}>
                        <Typography variant="h6">User description</Typography>
                        <Typography variant="body1">This is a description of {userName}.</Typography>
                        <textarea value={internalUserDescription} onChange={(e) => {
                            setInternalUserDescription(e.target.value);
                            
                        }} onBlur={() => {
                            saveSettings({...settings, user_details:internalUserDescription});
                        }}
                        ></textarea>

                    </Box>

                </Paper>
                {/* org description */}
                <Paper>
                    <Box p={2}>
                        <Typography variant="h6">Organization description</Typography>
                        <Typography variant="body1">This is a description of {subscriptionName}.</Typography>
                        <textarea value={internalOrgDescription} onChange={(e) => {
                            setInternalOrgDescription(e.target.value);
                            
                        }} onBlur={() => {
                            saveSettings({...settings, org_details:internalOrgDescription});
                        }}
                        ></textarea>
                    </Box>
                </Paper>
                {/* user role description */}
                <Paper>
                    <Box p={2}>
                        <Typography variant="h6">User role description</Typography>
                        <Typography variant="body1">This is a description of {userName}'s role in {subscriptionName}.</Typography>
                        <textarea value={internalUserRoleDescription} onChange={(e) => {
                            setInternalUserRoleDescription(e.target.value);
                            
                        }} onBlur={() => {
                            saveSettings({...settings, user_role_description:internalUserRoleDescription});
                        }}
                        ></textarea>
                    </Box>
                </Paper>
                {/* processes description */}
                <Paper>
                    <Box p={2}>
                        <Typography variant="h6">Processes description</Typography>
                        <Typography variant="body1">This is a description of {subscriptionName}'s processes.</Typography>
                        <textarea value={internalProcessesDescription} onChange={(e) => {
                            setInternalProcessesDescription(e.target.value);
                        }} onBlur={() => {
                            saveSettings({...settings, processes_details:internalProcessesDescription});
                        }}
                        ></textarea>
                    </Box>
                </Paper>




                

            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Typography variant="h6">Packages</Typography>
                        <Typography variant="body1">The following packages are enabled for {subscriptionName}:</Typography>
                        <ul>
                            {enabledPackages.map(packageName => {
                                return <li key={packageName}>
                                    <span>
                                    {packageName} 
                                    </span>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        const newEnabledPackages = enabledPackages.filter( p => { return p !== packageName});
                                        saveSettings({...settings, enabled_packages:newEnabledPackages});
                                    }}>Disable</Button>
                                    </li>
                            })}
                        </ul>
                        <Typography variant="body1">The following packages are available for {subscriptionName}:</Typography>
                        <ul>
                            {potentialPackages.filter( packageName => { return !enabledPackages.includes(packageName)}).map(packageName => {
                                return <li key={packageName}>
                                        <span>
                                        {packageName}
                                    </span>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        const newEnabledPackages = [...enabledPackages,packageName];
                                        saveSettings({...settings,enabled_packages:newEnabledPackages});
                                    }}>Enable</Button>                                    
                                    </li>  

                                
                            })}
                        </ul>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Container>

  </>
)
}

export default Component;
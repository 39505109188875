import React, {useEffect,useContext,useState} from 'react';
import { AuthContext,FireactContext,SetPageTitle  } from '@fireactjs/core';
import {PaginationTable} from './PaginationTable'
import { SubscriptionContext } from '@fireactjs/saas';
import {UpdateTemplate} from './UpdateTemplate';
import AddTemplate from './AddTemplate';
import { Paper, Box, Container, Grid, Button, Avatar, Alert, Typography } from "@mui/material";


function Component ({loader}) {
  const {subscription} = useContext(SubscriptionContext);
  const subscriptionName = subscription.name?subscription.name:"";
  const { firebaseApp } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [addTemplateActive, setAddTemplateActive] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);  

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const CloudFunctions = firebaseApp.functions();
  useEffect(() => {
    setLoaded(false);
    setError(null);
    // get default permission level name

    // let subscriptions = [];
    let ttemplates = [];
    const templatesRef = firebaseApp.firestore().collection('subscriptions/'+subscription.id+"/templates");
    const templatesQuery = templatesRef; //.where('subscription', '==', subscription.id);
    Promise.all([templatesQuery.get()]).then(([tSnapshot]) => {

        tSnapshot.forEach(record => {
            ttemplates.push({
                id: record.id,
                sender: record.data().sender,
                displayName: record.data().displayName || "Untitled",
                description: record.data().description || "",
                content: record.data().content || "",
                actionCol:<Button size="small" variant="outlined" onClick={() => setSelectedTemplate({
                  id: record.id,
                  sender: record.data().sender,
                  displayName: record.data().displayName || "Untitled",
                  description: record.data().description || "",
                  content: record.data().content || "",
                })}>Update</Button>
            })
            
        })

          setRows(ttemplates);
      setTotal(ttemplates.length);

      if(addTemplateActive === false && selectedTemplate === null){
        window.scrollTo(0, 0);
    }
        setTemplates(ttemplates);
        setLoaded(true);
    }).catch(error => {
        setLoaded(true);
        setError(error.message);
    })
},[addTemplateActive, firebaseApp, selectedTemplate, subscription]);


return (
  <>
      <SetPageTitle title={"User List"+(subscriptionName!==""?(" - "+subscriptionName):"")} />
      {loaded?(
          <>
          {selectedTemplate !== null?(
              <UpdateTemplate  template={selectedTemplate} setSelectedTemplate={setSelectedTemplate} setTemplates={setTemplates} />
          ):(
              <>
              {addTemplateActive?(
                  <AddTemplate setAddTemplateActive={setAddTemplateActive} setTemplates={setTemplates} />
              ):(
                  <Container maxWidth="lx">
                      {error !== null?(
                          <Alert severity="error">{error}</Alert>
                      ):(
                          <Paper>
                              <Box p={2}>
                                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                      <Grid item>
                                          <Typography component="h1" variant="h4">Template List</Typography>
                                      </Grid>
                                      <Grid item textAlign="right">
                                          <Button variant="contained" onClick={() => setAddTemplateActive(true)}>Add Template</Button>
                                      </Grid>
                                  </Grid>
                              </Box>
                              <Box p={2}>
                                  <PaginationTable columns={[
                                      {name: "Name", field: "displayName", style: {width: '30%'}},
                                      {name: "Description", field: "description", style: {width: '35%'}},
                                      {name: "Action", field: "actionCol", style: {width: '15%'}}
                                  ]}
                                  rows={rows}
                                  totalRows={total}
                                  pageSize={pageSize}
                                  page={page}
                                  handlePageChane={(e, p) => {
                                      setPage(p);
                                  }}
                                  handlePageSizeChange={(e) => {
                                      setPage(0);
                                      setPageSize(e.target.value);
                                  }}
                                  />
                              </Box>
                          </Paper>
                      )}
                  </Container>
              )}
              </>
          )}
          </>
      ):(
          <>{loader}</>
      )}
  </>
)
}

export default Component;
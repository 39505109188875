
import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { Alert, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { SubscriptionContext } from '@fireactjs/saas';
import "firebase/compat/functions";
const Component = ({setAddTemplateActive, setTemplates}) => {

    const { subscription } = useContext(SubscriptionContext);
    const subscriptionName = subscription.name?subscription.name:"";

    const { firebaseApp } = useContext(AuthContext);
    const CloudFunctions = firebaseApp.functions();

    const { config } = useContext(FireactContext);
    const permissions = config.saas.permissions || {};
    const defaultPermissions = [];
    for(let p in permissions){
        if(permissions[p].default){
            defaultPermissions.push(p);
        }
    }

    const [ processing, setProcessing ] = useState(false);

    const [ description, setDescription ] = useState('');
    const [ displayName, setDisplayName ] = useState('');
    const [ content, setContent ] = useState('');

    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(false);

    return (
        <Container maxWidth="md">
            <SetPageTitle title={"Add Template"+(subscriptionName!==""?(" - "+subscriptionName):"")} />
            <Paper>
                <Box p={2}>
                    <Typography component="h1" variant="h4" align="center">Add Template</Typography>
                </Box>
                {error !== null &&
                    <Box p={2}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                }
                {success &&
                    <Box p={2}>
                        <Alert severity="success">Template Added</Alert>
                    </Box>
                }
                <Box p={2}>
                <TextField required fullWidth name="name" label="Name" type="text" margin="normal" onChange={(e) => {setDisplayName(e.target.value)}} />
                <TextField multiline={true} required fullWidth name="description" label="Description" type="text" margin="normal" onChange={(e) => {setDescription(e.target.value)}} />
                <TextField multiline={true} required fullWidth name="content" label="Content" type="text" margin="normal" onChange={(e) => {setContent(e.target.value)}} />
                   
                </Box>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs>
                            <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => setAddTemplateActive(false)} >Back</Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" color="primary" variant="contained" disabled={processing} onClick={() => {    
                                setProcessing(true);
                                setError(null);
                                setSuccess(false);
                                const templatesRef = firebaseApp.firestore().collection('subscriptions/'+subscription.id+"/templates");
                                templatesRef.add({
                                    displayName: displayName,
                                    description: description,
                                    content: content,
                                }).then(res => {
                                    setTemplates(prevState => {
                                        prevState.push(
                                            {
                                                displayName: displayName,
                                                description: description,
                                                content: content,
                                                id: res.id,
                                            }
                                        )
                                        return prevState;
                                    });
                                    setProcessing(false);
                                    setSuccess(true);
                                }).catch(error => {
                                    setError(error.message);
                                    setProcessing(false);
                                })
                            }} >Add</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}

export default Component;